import { db } from "./config";
import { collection, addDoc, onSnapshot } from "firebase/firestore";

export const addEmotionResult = async (emotionResult) => {
    try {
        await addDoc(collection(db, "emotions"), emotionResult);
    } catch (error) {
        console.error("Error adding document: ", error);
    }
}

export const subscribeToEmotions = (callback) => {
    return onSnapshot(collection(db, "emotions"), callback);
}