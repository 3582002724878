// Scramble a word
export const scrambleWord = (word) => {
    let arr = word.split('');
    let n = arr.length;

    // First pass: Sattolo's Cycle
    for (let i = n - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * i);
        [arr[i], arr[j]] = [arr[j], arr[i]]; // Swap elements
    }

    // Fallback: Adjust any remaining characters in their original position
    for (let i = 0; i < n; i++) {
        if (arr[i] === word[i]) {
            // Find a candidate for swapping
            for (let j = 0; j < n; j++) {
                if (arr[j] !== word[j] && arr[j] !== word[i] && arr[i] !== word[j]) {
                    [arr[i], arr[j]] = [arr[j], arr[i]]; // Swap elements
                    break;
                }
            }
        }
    }

    // Final pass: Verify the derangement, especially for edge cases
    for (let i = 0; i < n; i++) {
        if (arr[i] === word[i]) {
            // For any remaining conflicts, swap with the next non-conflicting position
            let nextSwapIndex = (i + 1) % n;
            while (arr[nextSwapIndex] === word[nextSwapIndex] || arr[nextSwapIndex] === word[i]) {
                nextSwapIndex = (nextSwapIndex + 1) % n;
            }
            [arr[i], arr[nextSwapIndex]] = [arr[nextSwapIndex], arr[i]];
        }
    }

    return arr.join('');
};