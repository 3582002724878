import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { useTheme } from '../../../contexts/ThemeContext';
import {GAMES} from "../../../constants/simlr";
import CustomText from "../../atoms/CustomText";
import {getShortTextDateFromDateString} from "../../../utils/dateUtil";
import {useSimlrContext} from "../../../contexts/SimlrContext";

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
`;

const ModalInnerContainer = styled.div`
    display: flex;
    margin-top: -200px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #faedcd;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    max-height: 400px;
    z-index: 5;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 5px;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #b4b4b4;
        border-radius: 5px;
        border: none;
    }

    &::-webkit-scrollbar-track {
        background-color: #ffffff;
        border: none;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #fff;
        border: none;
    }
`;

const ModalButton = styled.button`
    background-color: transparent;
    color: #000;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    padding: 10px 24px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    width: 100%;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    
    &:hover {
        background-color: #00000020;
    }
    
    &:active {
        background-color: #00000040;
        transform: scale(0.95);
    }
    
    &:focus {
        outline: none;
    }
`;

const PreviousGamesList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-height: 400px;
    // padding-bottom: 50px;
`;

const PreviousGameItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 8px 20px;
    background-color: ${props => props.theme.simlrColors.primary};
    border-radius: 10px;
    width: 100%;
`;

const PreviousGameItemLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

const PreviousGameSmallText = styled(CustomText)`
    font-size: 1em;
    color: ${props => props.theme.simlrColors.text};
`;

const PreviousGameFadeText = styled(CustomText)`
    font-size: 1em;
    opacity: 0.5;
    color: ${props => props.theme.simlrColors.text};
`;

const SimlrSettingsModal = ({ show, handleClose }) => {
    const { theme } = useTheme();
    const { handleGiveUp, handleGetHint, handleSpecificGameClick } = useSimlrContext();
    const [showCalendar, setShowCalendar] = useState(false);
    const [reversedGames, setReversedGames] = useState();

    useEffect(() => {
        const reversed = [...GAMES].reverse();
        const filtered = reversed.filter(game => new Date(game.date) < new Date());
        setReversedGames(filtered);
    }, []);

    const handleGameClick = (game) => {
        setShowCalendar(false);
        handleSpecificGameClick(game);
        handleClose();
    }

    if (!show) {
        return null;
    }

    return (
        <ModalContainer>
            <ModalInnerContainer>
                {!showCalendar ? (
                    <>
                        <ModalButton onClick={handleGetHint}>Get Hint</ModalButton>
                        <ModalButton onClick={handleGiveUp}>Give Up</ModalButton>
                        <ModalButton onClick={() => setShowCalendar(!showCalendar)}>Previous Games</ModalButton>
                        <ModalButton onClick={handleClose}>Close</ModalButton>
                    </>
                ) : (
                    <>
                        <ModalButton onClick={() => setShowCalendar(!showCalendar)} style={{ marginTop: '20px' }}>Back</ModalButton>
                        <PreviousGamesList>
                            {reversedGames.map((game, index) => (
                                <PreviousGameItem key={index} onClick={() => handleGameClick(game)} theme={theme} last={index === reversedGames.length - 1}>
                                    <PreviousGameItemLeft>
                                        <PreviousGameSmallText type="bold" theme={theme}>#{game.id}</PreviousGameSmallText>
                                        <PreviousGameSmallText type="medium" theme={theme}>{getShortTextDateFromDateString(game.date)}</PreviousGameSmallText>
                                    </PreviousGameItemLeft>
                                    <PreviousGameFadeText theme={theme}>{game.result}</PreviousGameFadeText>
                                </PreviousGameItem>
                            ))}
                        </PreviousGamesList>
                    </>
                )}
            </ModalInnerContainer>
        </ModalContainer>
    );
}

export default SimlrSettingsModal;
