import styled from 'styled-components';
import CustomText from "../components/atoms/CustomText";
import ContactButton from "../components/molecules/ContactButton";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../contexts/ThemeContext";
import ScrambledBanner from "../assets/banners/ScrambledBanner.png";
import LifeStatsBanner from "../assets/banners/LifeStatsBanner.png";
import SimlrBanner from "../assets/banners/SimlrBanner.png";
import TypinBanner from "../assets/banners/TypinBanner.png";
import VibeCheckBanner from "../assets/banners/VibeCheckBanner.png";
import { FaTwitter } from "react-icons/fa6";
import ThemeButton from "../components/molecules/ThemeButton";

const ScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    background-color: ${props => props.theme.colors.background};
`;

const BottomRightContainer = styled.div`
    position: absolute; 
    bottom: 0px;
    right: 10px;
    
    @media (max-width: 800px) {
        display: none;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    margin-top: 10px;
`;

const GamesGrid = styled.div`
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 30px 30px;
    width: 90%;
    max-width: 1300px;
    align-items: center;
    
    @media (max-width: 1200px) {
        gap: 20px 20px;
    }
    
    @media (max-width: 1000px) {
        width: 85%;
    }
    
    @media (max-width: 800px) {
        margin-top: 30px;
        gap: 0px 10px;
    }
`;

const Footer = styled.div`
    position: fixed;
    bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
`;

const FooterText = styled(CustomText)`
    font-size: 1em;
    opacity: 0.7;
`;

const TwitterIcon = styled(FaTwitter)`
    font-size: 1.5em;
    color: ${props => props.theme.colors.text};
    opacity: 0.7;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
    
    &:active {
        transform: scale(0.9);
    }
`;

const Title = styled(CustomText)`
    font-size: 3.5em;
    margin: 0;
    
    @media (max-width: 800px) {
        font-size: 3em;
    }
`;

const Subtitle = styled(CustomText)`
    font-size: 1.2em;
    margin-top: -15px;
    opacity: 0.7;
    
    @media (max-width: 800px) {
        font-size: 1.2em;
    }
`;

const ImageGameCard = styled.img`
    width: 100%;
    border-radius: 20px;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    border: 3px solid ${props => props.theme.colors.text};
    
    @media (max-width: 800px) {
        margin-bottom: 10px;
    }
    
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }
`;

const HomeScreen = () => {
    const { theme } = useTheme();
    const navigate = useNavigate();

    const goToSimlr = () => {
        navigate("/simlr");
    }

    const goToScrambled = () => {
        navigate("/scrambled");
    }

    const goToLifeInADot = () => {
        navigate("/life-stats");
    }

    const goToTypin = () => {
        navigate("/typin");
    }

    const goToMood = () => {
        navigate("/mood");
    }

    const goToPrivacyPolicy = () => {
        navigate("/privacy-policy");
    }

    return (
        <ScreenContainer theme={theme}>
            <BottomRightContainer>
                <ContactButton text="Game idea?" onClick={() => window.open("mailto:nevstads@gmail.com")} />
            </BottomRightContainer>
            <Header>
                <Title type="gloria">silas.fun</Title>
                <Subtitle type="gloria">a tiny website made by silas</Subtitle>
            </Header>
            <GamesGrid theme={theme}>
                <ImageGameCard src={SimlrBanner} alt="Simlr Banner" onClick={goToSimlr} theme={theme} />
                <ImageGameCard src={TypinBanner} alt="Typin Banner" onClick={goToTypin} theme={theme} />
                <ImageGameCard src={VibeCheckBanner} alt="Typin Banner" onClick={goToMood} theme={theme} />
                <ImageGameCard src={LifeStatsBanner} alt="Life Stats Banner" onClick={goToLifeInADot} theme={theme} />
                <ImageGameCard src={ScrambledBanner} alt="Scrambled Banner" onClick={goToScrambled} theme={theme} />
            </GamesGrid>
            <Footer>
                <FooterText onClick={goToPrivacyPolicy} style={{cursor: "pointer"}}>Privacy Policy</FooterText>
                <FooterText>•</FooterText>
                <TwitterIcon onClick={() => window.open("https://twitter.com/nevstads")} theme={theme} />
            </Footer>
            <ThemeButton />
        </ScreenContainer>
    );
}

export default HomeScreen;