import styled from "styled-components";
import { useTheme } from "../contexts/ThemeContext";
import { useState } from "react";
import { SimlrProvider } from "../contexts/SimlrContext";
import SimlrGameSelection from "../components/molecules/Simlr/SimlrGameSelection";
import SimlrTopBar from "../components/molecules/Simlr/SimlrTopBar";
import SimlrInfoBar from "../components/molecules/Simlr/SimlrInfoBar";
import SimlrLoader from "../components/molecules/Simlr/SimlrLoader";
import GuessList from "../components/GameGuesses";
import SimlrSettingsModal from "../components/molecules/Simlr/SimlrSettingsModal";
import FooterTag from "../components/molecules/FooterTag";
import SimlrInput from "../components/molecules/Simlr/SimlrInput";
import SimlrHowTo from "../components/molecules/Simlr/SimlrHowTo";
import { useNavigate } from "react-router-dom";
import CustomText from "../components/atoms/CustomText";

const ScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: ${props => props.theme.simlrColors.background};
`;

const TopLeftContainer = styled.div`
    position: absolute;
    top: 0;
    left: 10px;
    padding: 10px;
`;

const BackText = styled(CustomText)`
    font-size: 1.4em;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: ${props => props.theme.simlrColors.text};
    
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }
    
    &:active {
        transform: scale(0.95);
    }
`;

const InnerContainer = styled.div`
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 450px;
    height: 100%;
    gap: 10px;
    
    @media (max-width: 800px) {
        width: 90%;
        padding: 10px;
    }
`;

function SimlrScreen() {
    const { theme } = useTheme();
    const navigate = useNavigate();
    const [showSettings, setShowSettings] = useState(false);

    const goToHome = () => {
        navigate('/');
    }

    return (
        <SimlrProvider>
            <ScreenContainer theme={theme}>
                {window.innerWidth > 800 &&
                    <TopLeftContainer>
                        <BackText type="gloria" onClick={goToHome} theme={theme}>Back</BackText>
                    </TopLeftContainer>
                }
                <SimlrGameSelection />
                <InnerContainer>
                    <SimlrTopBar setShowSettings={setShowSettings} showSettings={showSettings} />

                    <SimlrInfoBar />

                    <SimlrInput />

                    <SimlrLoader />

                    <GuessList />

                    <SimlrHowTo />

                    <SimlrSettingsModal
                        show={showSettings}
                        handleClose={() => setShowSettings(false)}
                    />
                </InnerContainer>
                <FooterTag textColor={theme.simlrColors.text} />
            </ScreenContainer>
        </SimlrProvider>
    );
}

export default SimlrScreen;