import React from "react";
import { useTheme } from "../../../contexts/ThemeContext";
import styled, { css } from "styled-components";
import CustomText from "../../atoms/CustomText";

const LetterCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 13px;
    height: 100%;
    cursor: grab;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    
    ${({ isCorrect, theme }) => isCorrect && css`
        color: ${theme.palette1.brightPrimary};
    `}
    
    ${({ isSelected, theme }) => isSelected && css`
        background-color: transparent;
        border-bottom: 8px solid ${theme.colors.primary};
    `}
        
    &:hover {
        cursor: pointer;
        transform: scale(1.2);
    }
    
    &:active {
        transform: scale(0.95);
    }
`;

const Letter = styled(CustomText)`
    font-size: 5em;
    color: ${props => props.isCorrect ? props.theme.palette1.primary : props.theme.palette1.grey};
    
    @media (max-width: 800px) {
        font-size: 3em;
    }
`;

const ScrambledLetterCard = ({ letter, isSelected, isCorrect, ...props }) => {
    const { theme } = useTheme();
    return (
        <LetterCardContainer
            {...props}
            isSelected={isSelected}
            isCorrect={isCorrect}
            theme={theme}
            onDragStart={(e) => {
                e.dataTransfer.setData("text/plain", null); // for Firefox compatibility
                props.onDragStart(e);
                e.currentTarget.style.transform = "scale(1.5)";
                e.currentTarget.style.fontWeight = "bold";
            }}
            onDragEnd={(e) => {
                e.currentTarget.style.transform = "scale(1)";
                e.currentTarget.style.fontWeight = "normal";
                e.currentTarget.style.borderBottom = "none";
            }}
            onDragOver={(e) => {
                e.preventDefault(); // Necessary to allow drop
                e.currentTarget.style.transform = "scale(1.2)";
                e.currentTarget.style.borderBottom = "8px solid " + theme.colors.primary;
            }}
            onDragLeave={(e) => {
                e.currentTarget.style.transform = "scale(1)";
                e.currentTarget.style.borderBottom = "none";
            }}
            onDrop={(e) => {
                props.onDrop(e);
                e.currentTarget.style.transform = "scale(1)";
                e.currentTarget.style.borderBottom = "none";
            }}
        >
            <Letter theme={theme} type="bold" isCorrect={isCorrect}>{letter}</Letter>
        </LetterCardContainer>
    );
};

export default ScrambledLetterCard;