import React from "react";
import styled from "styled-components";
import { useTheme } from "../../../contexts/ThemeContext";
import { useScrambledContext } from "../../../contexts/ScrambledContext";
import CustomButton from "../../atoms/CustomButton";

const MenuContainer = styled.div`
    position: absolute;
    bottom: -80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 15px;
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
`;

const MenuItem = styled(CustomButton)`
    background-color: ${props => props.theme.palette1.primary};
    color: ${props => props.theme.palette1.white};
    border: none;
    border-radius: 6px;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    font-size: .9em;
    padding: 8px 10px;
    
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
        background-color: ${props => props.theme.palette1.brightPrimary};
    }
    
    &:active {
        transform: scale(0.95);
    }
`;

const ScrambledGameButtons = () => {
    const { theme } = useTheme();
    const { startNewGame, revealLetter, revealWord } = useScrambledContext();

    const handleStartNewGame = () => {
        startNewGame();
    }

    return (
        <MenuContainer theme={theme}>
            <RowContainer>
                <MenuItem onClick={handleStartNewGame} theme={theme}>New Game</MenuItem>
                <MenuItem onClick={revealLetter} theme={theme}>Reveal Letter</MenuItem>
                <MenuItem onClick={revealWord} theme={theme}>Reveal Word</MenuItem>
            </RowContainer>
        </MenuContainer>
    );
};


export default ScrambledGameButtons;