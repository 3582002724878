import React from "react";
import styled from "styled-components";
import { useTheme } from "../contexts/ThemeContext";
import ScrambledWord from "../components/molecules/Scrambled/ScrambledWord";
import CustomText from "../components/atoms/CustomText";
import {ScrambledProvider} from "../contexts/ScrambledContext";
import ScrambledGameButtons from "../components/molecules/Scrambled/ScrambledGameButtons";
import ScrambledInfo from "../components/molecules/Scrambled/ScrambledInfo";
import {useNavigate} from "react-router-dom";
import ScrambledMenu from "../components/molecules/Scrambled/ScrambledMenu";

const ScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 100%;
    min-height: 100vh;
    background-color: ${props => props.theme.palette1.background};
    position: relative;
`;

const TopLeftContainer = styled.div`
    position: absolute;
    top: 0;
    left: 10px;
    padding: 10px;
`;

const BackText = styled(CustomText)`
    font-size: 1.6em;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: ${props => props.theme.palette1.text};
    
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }
    
    &:active {
        transform: scale(0.95);
    }
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: -100px;
    position: relative;
`;

const ScrambledScreen = () => {
    const navigate = useNavigate();
    const { theme } = useTheme();

    const goToHome = () => {
        navigate('/');
    }

    return (
        <ScrambledProvider>
            <ScreenContainer theme={theme}>
                <TopLeftContainer>
                    <BackText type="gloria" onClick={goToHome} theme={theme}>silas.fun</BackText>
                </TopLeftContainer>
                <InnerContainer>
                    <ScrambledInfo />
                    <ScrambledWord />
                    <ScrambledGameButtons />
                </InnerContainer>
                <ScrambledMenu />
                {/*<HeaderTag />*/}
            </ScreenContainer>
        </ScrambledProvider>
    );
}

export default ScrambledScreen;