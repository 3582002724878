import React from "react";
import styled, {css} from "styled-components";
import { useTheme } from '../../../contexts/ThemeContext';
import { useTypinContext } from '../../../contexts/TypinContext';
import CustomText from "../../atoms/CustomText";
import { FaRedoAlt } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { getTwitterUrl } from "@phntms/react-share";

const ResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: 100%;
`;

const ScoreContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;

const Score = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
`;

const ResultsText = styled(CustomText)`
    font-size: 4em;
    color: ${props => props.theme.colors.primary};
`;

const ResultsLabel = styled(CustomText)`
    font-size: 2em;
    color: ${props => props.theme.colors.text};
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;

const iconStyles = css`
    font-size: 1.5em;
    opacity: 0.7;
    color: ${props => props.theme.colors.text};
  
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
    
    &:active {
        transform: scale(0.9);
    }
`;

const RestartIcon = styled(FaRedoAlt)`
    ${iconStyles}
`;

const TwitterIcon = styled(FaXTwitter)`
    ${iconStyles}
`;

const ButtonMenu = () => {
    const { newGame, language, wpm, accuracy } = useTypinContext();
    const { theme } = useTheme();

    const handleShareTwitter = () => {
        const url = getTwitterUrl({
            text: `I just scored ${wpm} WPM with ${accuracy}% accuracy on Typin! Try it out now!`,
            url: "https://siii.fun/typin",
        });

        window.open(url, "_blank");
    }

    return (
        <ButtonsContainer>
            <RestartIcon theme={theme} onClick={() => newGame(language)} />
            <TwitterIcon theme={theme} onClick={handleShareTwitter} />
        </ButtonsContainer>
    );
}

const Results = () => {
    const { theme } = useTheme();
    const { wpm, accuracy } = useTypinContext();

    return (
        <ResultsContainer>
            <ScoreContainer>
                <Score>
                    <ResultsText theme={theme} type="regular">
                        {wpm}
                    </ResultsText>
                    <ResultsLabel theme={theme} type="regular">wpm</ResultsLabel>
                </Score>
                <Score>
                    <ResultsText theme={theme} type="regular">
                        {accuracy}%
                    </ResultsText>
                    <ResultsLabel theme={theme} type="regular">acc</ResultsLabel>
                </Score>
            </ScoreContainer>
            <ButtonMenu />
        </ResultsContainer>
    );
}

export default Results;