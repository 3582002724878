import React, { useEffect } from 'react';
import styled from 'styled-components';
import CustomText from '../../atoms/CustomText';
import { useTheme } from '../../../contexts/ThemeContext';
import { useTypinContext } from '../../../contexts/TypinContext';

const CountdownContainer = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // width: 100%;
    // height: 100%;
    // gap: 10px;
`;

const CountdownText = styled(CustomText)`
    position: absolute;
    top: -80px;
    font-size: 2em;
    font-weight: bold;
    color: ${props => props.theme.colors.primary};
`;

const Countdown = () => {
    const { theme } = useTheme();
    const { timer } = useTypinContext();

    return (
        // <CountdownContainer>
        <CountdownText theme={theme} type="regular">
            {timer}
        </CountdownText>
        // </CountdownContainer>
    );
};

export default Countdown;