import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from "../../../contexts/ThemeContext";
import CustomInput from "../../atoms/CustomInput";

const StyledInput = styled(CustomInput)`
    width: calc(100% - 30px);
    padding: 10px;
    border-radius: 10px;
    font-size: 1.5em;
    margin-top: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    
    @media (max-width: 768px) {
        font-size: 1.2em;
    }
`;

const Input = ({ onSubmit }) => {
    const { theme } = useTheme();
    const [text, setText] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(text);
    };

    return (
        <form onSubmit={handleSubmit}>
            <StyledInput
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Type here..."
                theme={theme}
            />
        </form>
    );
}

export default Input;