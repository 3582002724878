export const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + '...';
}

export function getBackgroundColor(similarity) {
    const fill = similarity * 100;
    const opacity = 0.7;

    let red, green;
    if (similarity > 0.5) {
        green = 255;
        red = Math.floor((2 * 255 * (1 - similarity)) * 0.7);
    } else {
        red = 255;
        green = Math.floor((2 * 255 * similarity) * 0.7);
    }

    const blue = 100;

    return { color: `rgba(${red},${green},${blue}, ${opacity})`, fill };
};

export const formatNumber = (number) => {
    if (!number) return '';
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};