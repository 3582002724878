// given a date string in the format 'YYYY-MM-DD' return a date object
import {GAMES} from "../constants/simlr";

const convertDate = (date) => {
    // Split the date string into its molecules
    const [year, month, day] = date.split('-').map(num => parseInt(num, 10));

    return new Date(Date.UTC(year, month - 1, day));
}

// Gets a short text representation of the date, e.g., "Tue, Mar 26"
export const getShortTextDate = (date) => {
    return date.toUTCString().slice(0, 12);
}

// # Given a date object, return a string in the format '26 Mar'
export const getDayAndMonthString = (date) => {
    return date.toUTCString().slice(5, 11);
}

// Given a date object, return a string in the format 'Wed 25'
export const getWeekdayAndMonthString = (date) => {
    const str = date.toUTCString().slice(0, 11);
//     remove comma
    return str.slice(0, 3) + str.slice(4, 11);
}


export const getWeekdayAndMonthStringPlusDays = (date, days) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return getWeekdayAndMonthString(newDate);
}

export const getShortTextDateFromDateString = (dateString) => {
    return getShortTextDate(convertDate(dateString));
}

export const getTodaysGame = () => {
    const today = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
    const todayString = today.toISOString().slice(0, 10);
    return GAMES.find(game => game.date === todayString);
}

export const presentPercentage = (num) => {
//      given a numer (0.0-1.0) return two separate values the whole number and the decimal (only one)
    const whole = Math.floor(num * 100);
    const decimal = Math.round((num * 100 - whole) * 10);
    if (decimal === 10) {
        return [whole + 1, 0];
    }
    return [whole, decimal];
}