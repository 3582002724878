import styled, { css } from 'styled-components';
import { useNavigate } from "react-router-dom";
import { useTheme } from "../contexts/ThemeContext";
import CustomText from "../components/atoms/CustomText";
import CustomInput from "../components/atoms/CustomInput";
import React, {useEffect, useRef, useState} from "react";
import { RiLungsLine } from "react-icons/ri";
import { BiMeteor } from "react-icons/bi";
import { MdAir } from "react-icons/md";
import { TbMoneybag } from "react-icons/tb";
import { FaPersonSwimming } from "react-icons/fa6";
import { PiHeartBold, PiEyeBold, PiWatchBold, PiWaveformBold, PiHamburgerBold, PiThermometerSimpleBold,
    PiBedDuotone, PiBrainBold, PiCalendarBold, PiMoonBold, PiSunBold, PiSmileySadBold, PiDropBold, PiSmileyBold,
    PiChatCenteredDotsBold, PiClockAfternoonBold, PiFootprintsBold, PiRocketBold, PiBookOpenTextBold } from "react-icons/pi";
import { IoCalendarOutline, IoCalendarNumberOutline } from "react-icons/io5";
import { FiDatabase } from "react-icons/fi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { TbTree } from "react-icons/tb";
import { MdOutlineCo2 } from "react-icons/md";
import {calculateLifeStats, MONTHS, validateAndSetBirthDate, validMonth} from "../utils/lifeUtils";
import {formatNumber} from "../utils/helpers";
import FooterTag from "../components/molecules/FooterTag";
import CustomButton from "../components/atoms/CustomButton";
import ThemeButton from "../components/molecules/ThemeButton";

const ScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh; // Ensures it covers at least the full viewport height initially
    background-color: ${props => props.theme.colors.background}; // Applies background color
`;


const TopLeftContainer = styled.div`
    position: absolute;
    top: 0;
    left: 10px;
    padding: 10px;
    z-index: 100;
    
    @media (max-width: 800px) {
        right: 10px;
    }
`;

const TopInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 10px;
    
    @media (max-width: 800px) {
        top: 60px;
    }
`;

const InfoText = styled(CustomText)`
    font-size: 1.3em;
    opacity: 0.3;
    
    @media (max-width: 800px) {
        font-size: 1em;
    }
`;

const FooterContainer = styled.footer`
    position: fixed;
    bottom: 0;
    padding: 10px;
    z-index: 100;
`;

const FooterText = styled(CustomText)`
    font-size: 1em;
    color: ${props => props.theme.colors.text};
    opacity: 0.5;
`;

const BackText = styled(CustomText)`
    font-size: 1.4em;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: ${props => props.theme.colors.text};
    
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }
    
    &:active {
        transform: scale(0.95);
    }
`;

const InputContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
    margin-top: -100px;
`;

const InputRow = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    
    @media (max-width: 800px) {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
`;

const ErrorText = styled(CustomText)`
    position: absolute;
    top: -25px;
    font-size: 1.2em;
    color: ${props => props.theme.colors.error};
`;

const InputText = styled(CustomText)`
    font-size: 1.5em;
    margin: 0;
`;

const Input = styled(CustomInput)`
    padding: 10px;
    font-size: 1.5em;
    width: 100px;
    border: none;
    margin: 0;
    text-align: center;
    color: ${props => props.theme.colors.primary};
    // border-bottom: 2px solid ${props => props.theme.colors.primary};
    
    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    
    &:focus {
        outline: none;
        // border: 2px solid ${props => props.theme.colors.primary};
    }
    
    &::placeholder {
        color: ${props => props.theme.colors.primary};
        opacity: 0.5;
    }
`;

const SubmitButton = styled(CustomButton)`
    position: absolute;
    right: -130px;
    padding: 10px;
    font-size: 0.8em;
    
    @media (max-width: 800px) {
        right: 0;
        left: 0;
        bottom: -60px;
    }
`;

const VisualizationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 80%;
    height: 100%;
    gap: 20px;
    padding-bottom: 50px;
    margin-top: 60px;
    
    @media (max-width: 1500px) {
        flex-direction: column;
        gap: 10px;
        // width: 95%;
        margin-top: 60px;
    }
    
    @media (max-width: 800px) {
        width: 95%;
    }
`;

const VisualizationGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: calc(100% - 40px);
    background-color: ${props => props.theme.colors.cardBackground};
    padding: 20px;
    border-radius: 10px;
`;

const VisualizationGroupWrap = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    gap: 20px;
    width: 100%;
    padding: 10px;
    justify-items: start;
    
    @media (max-width: 800px) {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 10px;
    }
`;

const VisualizationGroupWrapSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
`;

const VisualizationGroupTitle = styled(CustomText)`
    font-size: 1.3em;
    color: ${props => props.theme.colors.text};
    opacity: 0.5;
`;

const LifeExpectancyText = styled(CustomText)`
    display: flex;
    align-items: center;
    text-align: left;
    flex-wrap: no-wrap;
    gap: 5px;
    margin: 5px 0;
    font-size: 1.1em;
    color: ${props => props.theme.colors.text};
    
    @media (max-width: 1500px) {
       font-size: 1em;
    }
`;

const iconStyles = css`
    font-size: 1.5em;
    color: ${props => props.theme.colors.primary};
    margin-top: -2px;
`;

const LifeExpectancyValue = styled(CustomText)`
    font-size: 1.2em;
    color: ${props => props.theme.colors.primary};
`;

const LungIcon = styled(RiLungsLine)`
    ${iconStyles}
`;

const AirIcon = styled(MdAir)`
    ${iconStyles}
`;

const SwimIcon = styled(FaPersonSwimming)`
    ${iconStyles}
`;

const HeartIcon = styled(PiHeartBold)`
    ${iconStyles}
`;

const EyeIcon = styled(PiEyeBold)`
    ${iconStyles}
`;

const MeteorIcon = styled(BiMeteor)`
    ${iconStyles}
`;

const BedIcon = styled(PiBedDuotone)`
    ${iconStyles}
`;

const BrainIcon = styled(PiBrainBold)`
    ${iconStyles}
`;

const CalendarIcon = styled(IoCalendarNumberOutline)`
    ${iconStyles}
`;

const CalendarIconOutline = styled(IoCalendarOutline)`
    ${iconStyles}
`;

const WatchIcon = styled(PiWatchBold)`
    ${iconStyles}
`;

const TreeIcon = styled(TbTree)`
    ${iconStyles}
`;

const PeopleIcon = styled(HiOutlineUserGroup)`
    ${iconStyles}
`;

const DatabaseIcon = styled(FiDatabase)`
    ${iconStyles}
`;

const CO2Icon = styled(MdOutlineCo2)`
    ${iconStyles}
    font-size: 2em;
`;

const MoonIcon = styled(PiMoonBold)`
    ${iconStyles}
`;

const HappyIcon = styled(PiSmileyBold)`
    ${iconStyles}
`;

const SadIcon = styled(PiSmileySadBold)`
    ${iconStyles}
`;

const SunIcon = styled(PiSunBold)`
    ${iconStyles}
`;

const ChatIcon = styled(PiChatCenteredDotsBold)`
    ${iconStyles}
`;

const WaterDropIcon = styled(PiDropBold)`
    ${iconStyles}
`;

const ClockIcon = styled(PiClockAfternoonBold)`
    ${iconStyles}
`;

const FootprintsIcon = styled(PiFootprintsBold)`
    ${iconStyles}
`;

const RocketIcon = styled(PiRocketBold)`
    ${iconStyles}
`;

const BookIcon = styled(PiBookOpenTextBold)`
    ${iconStyles}
`;

const WaveformIcon = styled(PiWaveformBold)`
    ${iconStyles}
`;

const BurgerIcon = styled(PiHamburgerBold)`
    ${iconStyles}
`;

const ThermometerIcon = styled(PiThermometerSimpleBold)`
    ${iconStyles}
`;

const GarbageBagIcon = styled(TbMoneybag)`
    ${iconStyles}
`;

const LifeStatsScreen = () => {
    const navigate = useNavigate();
    const { theme } = useTheme();
    const [error, setError] = useState('');
    const [showSubmitButton, setShowSubmitButton] = useState(false);
    const [birthDate, setBirthDate] = useState(new Date());
    const [birthYear, setBirthYear] = useState('');
    const [birthMonth, setBirthMonth] = useState('');
    const [birthDay, setBirthDay] = useState('');
    const birthDayRef = useRef(null);
    const birthMonthRef = useRef(null);
    const birthYearRef = useRef(null);
    const [stats, setStats] = useState({});
    const [visualizing, setVisualizing] = useState(false);

    useEffect(() => {
        if (birthDay.length === 2 && birthMonthRef.current) {
            birthMonthRef.current.focus();
        }
    }, [birthDay, birthMonth]);


    useEffect(() => {
        const interval = setInterval(() => {
            if (visualizing && birthDate) {
                setStats(calculateLifeStats(birthDate));
            }
        }, 1000); // Update every second

        return () => clearInterval(interval);
    }, [visualizing, birthDate]);

    const handleSubmit = () => {
        const valid = validateAndSetBirthDate(birthDay, birthMonth, birthYear, setBirthDate);
        if (valid) {
            setVisualizing(true);
        } else {
            alert('Invalid date');
        }
    };

    const timedError = (message, seconds) => {
        setError(message);
        setTimeout(() => setError(''), seconds * 1000);
    };

    const handleMonthEdit = (e) => {
        if (e.key === 'Backspace' && birthMonth === '') {
            birthDayRef.current.focus();
        }
        if (e.key >= 0 && e.key <= 9) {
            birthYearRef.current.focus();
        }
        setBirthMonth(e.target.value);
    };

    const handleMonthSubmit = () => {
        if (MONTHS.indexOf(birthMonth.toLowerCase()) === -1) {
            timedError('Invalid month', 3);
            return;
        }
        birthYearRef.current.focus();
    };

    const handleYearEdit = (e) => {
        if (!validMonth(birthMonth)) {
            birthMonthRef.current.focus();
            timedError('Invalid month', 3);
            return;
        }
        if (e.key === 'Backspace' && birthYear === '') {
            birthMonthRef.current.focus();
        }
        if (e.target.value.length === 4) {
            setShowSubmitButton(true);
        }
        setBirthYear(e.target.value);
    }

    const goToHome = () => {
        navigate('/');
    };

    return (
        <ScreenContainer theme={theme}>
            <TopLeftContainer>
                <BackText onClick={goToHome} theme={theme} type="gloria">Back</BackText>
            </TopLeftContainer>
            {!visualizing &&
                <TopInfoContainer>
                    <InfoText theme={theme} type="semiBold">Just enter your birthday and hit enter</InfoText>
                </TopInfoContainer>
            }
            {visualizing ? (
                <VisualizationContainer>
                    <VisualizationGroup theme={theme}>
                        <VisualizationGroupTitle theme={theme} type="medium">
                            Time
                        </VisualizationGroupTitle>
                        <VisualizationGroupWrap>
                            <VisualizationGroupWrapSection>
                                <LifeExpectancyText theme={theme}>
                                    <WatchIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.secondsAlive)}</LifeExpectancyValue>
                                    seconds
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <WatchIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.minutesAlive)}</LifeExpectancyValue>
                                    minutes
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <ClockIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.hoursAlive)}</LifeExpectancyValue>
                                    hours
                                </LifeExpectancyText>
                            </VisualizationGroupWrapSection>
                            <VisualizationGroupWrapSection>
                                <LifeExpectancyText theme={theme}>
                                    <CalendarIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.daysAlive)}</LifeExpectancyValue>
                                    days
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <CalendarIconOutline theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.yearsAlive)}</LifeExpectancyValue>
                                    years
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <BedIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.hoursAsleep)}</LifeExpectancyValue>
                                    hours asleep
                                </LifeExpectancyText>
                            </VisualizationGroupWrapSection>
                        </VisualizationGroupWrap>
                    </VisualizationGroup>

                    <VisualizationGroup theme={theme}>
                        <VisualizationGroupTitle theme={theme} type="medium">
                            Internal
                        </VisualizationGroupTitle>
                        <VisualizationGroupWrap>
                            <VisualizationGroupWrapSection>
                                <LifeExpectancyText theme={theme}>
                                    <LungIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.breathsTaken)}</LifeExpectancyValue>
                                    breaths
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <LungIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.airInhaledLiters)}</LifeExpectancyValue>
                                    liters of air inhaled
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <SwimIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.olympicSwimmingPoolsInhaled)}</LifeExpectancyValue>
                                    swimming pools inhaled
                                </LifeExpectancyText>
                            </VisualizationGroupWrapSection>

                            <VisualizationGroupWrapSection>
                                <LifeExpectancyText theme={theme}>
                                    <HeartIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.heartbeats)}</LifeExpectancyValue>
                                    heartbeats
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <HeartIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.bloodPumped)}</LifeExpectancyValue>
                                    liters of blood pumped
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <SwimIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.olympicSwimmingPoolsBloodPumped)}</LifeExpectancyValue>
                                    swimming pools of blood pumped
                                </LifeExpectancyText>
                            </VisualizationGroupWrapSection>

                            <VisualizationGroupWrapSection>
                                <LifeExpectancyText theme={theme}>
                                    <EyeIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.blinks)}</LifeExpectancyValue>
                                    blinks
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <AirIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.fartsPassed)}</LifeExpectancyValue>
                                    farts
                                </LifeExpectancyText>
                            </VisualizationGroupWrapSection>

                            <VisualizationGroupWrapSection>
                                <LifeExpectancyText theme={theme}>
                                    <FootprintsIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.stepsTaken)}</LifeExpectancyValue>
                                    steps taken
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <ChatIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.wordsSpoken)}</LifeExpectancyValue>
                                    words spoken
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <BookIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.booksSpoken)}</LifeExpectancyValue>
                                    books spoken
                                </LifeExpectancyText>
                            </VisualizationGroupWrapSection>

                            <VisualizationGroupWrapSection>
                                <LifeExpectancyText theme={theme}>
                                    <BrainIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.dreamsHad)}</LifeExpectancyValue>
                                    dreams
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <HappyIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.laughsHad)}</LifeExpectancyValue>
                                    laughs
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <SadIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.criesHadMale)}</LifeExpectancyValue>
                                    cries
                                </LifeExpectancyText>
                            </VisualizationGroupWrapSection>
                        </VisualizationGroupWrap>
                    </VisualizationGroup>

                    <VisualizationGroup theme={theme}>
                        <VisualizationGroupTitle theme={theme} type="medium">
                            External
                        </VisualizationGroupTitle>
                        <VisualizationGroupWrap>
                            <VisualizationGroupWrapSection>
                                <LifeExpectancyText theme={theme}>
                                    <BurgerIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.foodWastedSinceBirth)}</LifeExpectancyValue>
                                    tons of food wasted
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <WaterDropIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.waterUsedLiters)}</LifeExpectancyValue>
                                    liters of water used
                                </LifeExpectancyText>
                            </VisualizationGroupWrapSection>
                            <VisualizationGroupWrapSection>
                                {/*<LifeExpectancyText theme={theme}>*/}
                                {/*    <PeopleIcon theme={theme} />*/}
                                {/*    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.globalPopulationChangeSinceBirth)}</LifeExpectancyValue>*/}
                                {/*    global change in population*/}
                                {/*</LifeExpectancyText>*/}
                                <LifeExpectancyText theme={theme}>
                                    <ThermometerIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.temperatureChangeSinceBirth)}</LifeExpectancyValue>
                                    °C global temperature change
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <GarbageBagIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.garbagePatchSizeGrowthSinceBirth)}</LifeExpectancyValue>
                                    km² of garbage patch growth
                                </LifeExpectancyText>
                            </VisualizationGroupWrapSection>

                            <VisualizationGroupWrapSection>
                                <LifeExpectancyText theme={theme}>
                                    <CO2Icon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.carbonEmissionsTons)}</LifeExpectancyValue>
                                    tons of CO2 emissions
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <TreeIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.treesNeededToOffsetCarbon)}</LifeExpectancyValue>
                                    trees needed to offset carbon
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <DatabaseIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.dataUsed)}</LifeExpectancyValue>
                                    GB of data consumed
                                </LifeExpectancyText>
                            </VisualizationGroupWrapSection>

                            <VisualizationGroupWrapSection>
                                <LifeExpectancyText theme={theme}>
                                    <SunIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.earthMilesToSun)}</LifeExpectancyValue>
                                    miles around the sun
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <RocketIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.earthMilesToMilkyWay)}</LifeExpectancyValue>
                                    miles through space
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <MeteorIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.meteorShowersSeen)}</LifeExpectancyValue>
                                    meteor showers
                                </LifeExpectancyText>
                            </VisualizationGroupWrapSection>

                            <VisualizationGroupWrapSection>

                                <LifeExpectancyText theme={theme}>
                                    <MoonIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.lunarEclipsesSeen)}</LifeExpectancyValue>
                                    lunar eclipses
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <SunIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.solarEclipsesSeen)}</LifeExpectancyValue>
                                    solar eclipses
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <MoonIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.fullMoonCycles)}</LifeExpectancyValue>
                                    full moon cycles
                                </LifeExpectancyText>
                                <LifeExpectancyText theme={theme}>
                                    <WaveformIcon theme={theme} />
                                    <LifeExpectancyValue theme={theme} type="semiBold">{formatNumber(stats.tidalCycles)}</LifeExpectancyValue>
                                    tidal cycles
                                </LifeExpectancyText>
                            </VisualizationGroupWrapSection>
                        </VisualizationGroupWrap>
                    </VisualizationGroup>
                </VisualizationContainer>
            ) : (
                <InputContainer theme={theme}>
                    {error && <ErrorText theme={theme}>{error}</ErrorText>}
                    <InputRow>
                        <InputText type='medium'>I was born on the</InputText>
                        <Input
                            ref={birthDayRef}
                            placeholder='1'
                            theme={theme}
                            value={birthDay}
                            onChange={(e) => setBirthDay(e.target.value)}
                            onSubmit={() => birthMonthRef.current.focus()}
                            style={{ color: theme.colors.primary, width: '30px' }}
                        />
                        <InputText type='medium'>of</InputText>
                        <Input
                            ref={birthMonthRef}
                            placeholder='January'
                            theme={theme}
                            value={birthMonth}
                            onKeyPress={handleMonthEdit}
                            onChange={handleMonthEdit}
                            onSubmit={handleMonthSubmit}
                            style={{ color: theme.colors.primary, width: '120px' }}
                        />
                        <InputText type='medium'>in</InputText>
                        <Input
                            ref={birthYearRef}
                            placeholder='2003'
                            theme={theme}
                            value={birthYear}
                            onKeyPress={handleYearEdit}
                            onChange={handleYearEdit}
                            onSubmit={handleSubmit}
                            style={{ color: theme.colors.primary, width: '60px' }}
                        />
                        {showSubmitButton && (
                            <SubmitButton
                                theme={theme}
                                onClick={handleSubmit}
                            >
                                Visualize
                            </SubmitButton>
                        )}
                    </InputRow>

                </InputContainer>
            )}
            {visualizing && (
                <FooterContainer>
                    <FooterText theme={theme} type="medium">Based on averages</FooterText>
                </FooterContainer>
            )}
            {!visualizing && <FooterTag textColor={theme.colors.text} />}
            <ThemeButton />
        </ScreenContainer>
    );
};

export default LifeStatsScreen;