import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import { useTypinContext } from '../../../contexts/TypinContext';
import { useTheme } from '../../../contexts/ThemeContext';
import {updateCurrentIndex} from "../../../utils/typinUtils";
import Countdown from "./Countdown";
import Restart from "./Restart";

const TypingAreaContainer = styled.div`
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
`;

const TypinInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
`;

const Row = styled.div`
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 5px;
`;

const Word = styled.span`
    margin-right: 8px;
    white-space: nowrap;
    position: relative;
`;

const Cursor = styled.span`
    position: absolute;
    right: 0;
    top: 0;
    background-color: ${({ theme }) => theme.colors.primary};
    opacity: 0.5;
    opacity: 1;
    width: 3px;
    height: 1.8em;
    margin-left: 0px;
    animation: flash 1s infinite step-start;

    @keyframes flash {
        50% {
            opacity: 0;
        }
    }
`;

const Letter = styled.span`
    transition: all 0.3s ease;
    color: ${({ state, theme }) => {
    if (state === 'correct') {
        return theme.typin.correct;
    } else if (state === 'incorrect') {
        return theme.typin.incorrect;
    } else {
        return theme.typin.untyped;
    }
}};
    text-decoration: ${({ state }) => (state === 'incorrect' ? 'underline' : 'none')};
    font-family: 'PoppinsMedium', sans-serif;
    letter-spacing: 1px;
    font-size: 1.6em;

    &:before {
        content: '';
        display: ${({ isCursor }) => (isCursor ? 'inline-block' : 'none')};
        background-color: ${({ theme }) => theme.colors.primary};
        position: absolute;
        top: 0px;
        opacity: 1;
        // filter: brightness(5.5);
        width: 3px;
        height: 1.2em;
        animation: blink 1s steps(5, start) infinite;
        
        @keyframes blink {
            50% {
                opacity: 0;
            }
        }
    }
    
    @media (max-width: 800px) {
        font-size: 1.4em;
    }
`;
const InvisibleInput = styled.input`
    opacity: 0;
    position: absolute;
    top: 0;
`;

const TypingArea = () => {
    const {
        rows, handleInputChange, currentIndex, gameState, currentRowCutOff, viewMode, toggleViewMode,
    } = useTypinContext();
    const { theme } = useTheme();
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        if (gameState === 'playing') {
            inputRef.current.focus();
        }
    }, [gameState]);

    const renderRows = () => rows.slice(currentRowCutOff, currentRowCutOff + 3).map((row, rowIndex) => (
        <Row key={`row-${rowIndex}`}>
            {row.map((wordObj, wordIndex) => (
                <Word key={`word-${wordIndex}`}>
                    {wordObj.letters.map((letter, letterIndex) => (
                        <React.Fragment key={`letter-${letterIndex}`}>
                            <Letter
                                key={`letter-${letterIndex}`}
                                state={letter.state}
                                theme={theme}
                                // Display cursor with the letter if this is the current typing position
                                isCursor={rowIndex + currentRowCutOff === currentIndex.row && wordIndex === currentIndex.word && letterIndex === currentIndex.letter}
                            >
                                {letter.char}
                            </Letter>
                        </React.Fragment>
                    ))}
                    {rowIndex + currentRowCutOff === currentIndex.row && wordIndex === currentIndex.word && currentIndex.letter === wordObj.letters.length && (
                        <Cursor theme={theme} />
                    )}
                </Word>
            ))}
        </Row>
    ));


    useEffect(() => {
        window.addEventListener('keydown', handleInputChange);
        return () => window.removeEventListener('keydown', handleInputChange);
    }, [handleInputChange]);

    return (
        <TypingAreaContainer>
            {gameState === 'playing' && <Countdown />}
            <TypinInputContainer>
                {renderRows()}
                <InvisibleInput
                    ref={inputRef}
                    value={inputValue}
                    onInput={handleInputChange}
                    autoComplete="off"
                    spellCheck="false"
                />
            </TypinInputContainer>
            {gameState === 'playing' || gameState === 'waiting' && <Restart />}
        </TypingAreaContainer>
    );
};

export default TypingArea;