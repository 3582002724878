import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import styled from "styled-components";

const StyledButton = styled.button`
    font-family: 'PoppinsMedium', sans-serif;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    border: 1px solid #00000060;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    
    &:hover {
        background-color: ${props => props.theme.colors.primaryDark};
        color: ${props => props.theme.colors.white};
    }
    
    &:active {
        transform: scale(0.98);
    }
`;

const CustomButton = ({ type, style, onClick, children, ...props }) => {
    const { theme } = useTheme();
    return (
        <StyledButton
            type={type}
            style={style}
            onClick={onClick}
            theme={theme}
            {...props}
        >
            {children}
        </StyledButton>
    );
}

export default CustomButton;