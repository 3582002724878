import commonWords from "../assets/words/words.json";
import englishBasic850 from "../assets/words/basic_english_850.json";
import englishBasic2000 from "../assets/words/basic_english_2000.json";
import spanishBasic from "../assets/words/common-spanish-10000.json";
import golangWords from "../assets/words/golang_words.json";
import pythonWords from "../assets/words/python_words.json";
import gitWords from "../assets/words/git_words.json";
import javascriptWords from "../assets/words/javascript_words.json";
import cssWords from "../assets/words/css_words.json";
import sqlWords from "../assets/words/sql_words.json";

export const getRowLength = () => {
    switch (true) {
        case window.innerWidth < 800:
            return 25;
        case window.innerWidth < 1000:
            return 40;
        case window.innerWidth < 1200:
            return 50;
        case window.innerWidth < 1400:
                return 55;
        case window.innerWidth < 2000:
            return 60;
        default:
            return 65;
    }
}

// longest language is english medium (13 characters)
export const formatLanguageString = (language, mobile) => {
    if (language === "css" || language === "sql") {
        return language.toUpperCase();
    }
    // uppercase first letter of each word and add space at end until at least 13 characters
    const words = language.split(' ');
    const formatted = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    while (formatted.join(' ').length < 13) {
        formatted.push(' ');
    }
    if (mobile) {
        return formatted.join(' ').replace('English', 'EN').replace('Spanish', 'SP');
    }
    return formatted.join(' ');
}

const efficient_merge_dedupe = (arr1, arr2) => {
    const set = new Set([...arr1, ...arr2]);
    return Array.from(set);
}

export const getLanguageWords = (language) => {
    switch (language) {
        case 'english':
            return efficient_merge_dedupe(commonWords, englishBasic850);
        case 'english 1000':
            return commonWords;
        case 'english 2000':
            return efficient_merge_dedupe(englishBasic850, englishBasic2000);
        case 'spanish':
            return spanishBasic;
        case 'golang':
            return golangWords;
        case 'python':
            return pythonWords;
        case 'javascript':
            return javascriptWords;
        case 'css':
            return cssWords;
        case 'sql':
            return sqlWords;
        case 'git':
            return gitWords;
        default:
            return englishBasic2000;

    }
}

export const getRandomWords = (lang, numWords) => {
    const langWords = getLanguageWords(lang);
    while (langWords.length < numWords) {
        langWords.push(...langWords);
    }
    const shuffled = langWords.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, numWords).map(word => ({
        word,
        letters: word.split('').map(char => ({ char, state: 'untyped' })),
    }));
};

export const splitWordsIntoRows = (words, maxCharsPerRow) => {
    let rows = [];
    let currentRow = [];
    let currentCharCount = 0;

    words.forEach(wordObj => {
        // Including space in count if currentRow is not empty
        const wordLength = wordObj.word.length + (currentRow.length > 0 ? 1 : 0);
        if (currentCharCount + wordLength <= maxCharsPerRow) {
            currentRow.push(wordObj);
            currentCharCount += wordLength;
        } else {
            rows.push(currentRow);
            currentRow = [wordObj];
            currentCharCount = wordObj.word.length;
        }
    });

    // Push the last row if it has words
    if (currentRow.length > 0) rows.push(currentRow);

    return rows;
};

// Function to calculate accuracy
export const calculateAccuracy = (rows) => {
    const correctLetters = rows.reduce((acc, row) => {
        return acc + row.reduce((acc, word) => {
            return acc + word.letters.reduce((acc, letter) => {
                return acc + (letter.state === 'correct' ? 1 : 0);
            }, 0);
        }, 0);
    }, 0);
    const incorrectLetters = rows.reduce((acc, row) => {
        return acc + row.reduce((acc, word) => {
            return acc + word.letters.reduce((acc, letter) => {
                return acc + (letter.state === 'incorrect' ? 1 : 0);
            }, 0);
        }, 0);
    }, 0);
    const totalLetters = correctLetters + incorrectLetters;
    return totalLetters > 0 ? ((correctLetters / totalLetters) * 100).toFixed(2) : "0.00";
};

// Function to calculate WPM
export const calculateWPM = (rows, startTime, endTime) => {
    const correctWords = rows.reduce((acc, row) => {
        return acc + row.reduce((acc, word) => {
            return acc + (word.letters.every(letter => letter.state === 'correct') ? 1 : 0);
        }, 0);
    }, 0);
    const minutes = (endTime - startTime) / 60000;
    return minutes > 0 ? (correctWords / minutes).toFixed(2) : "0.00";
};

export const updateCurrentIndex = ({action, rows, currentIndex, setCurrentIndex, setCurrentRowCutOff}) => {
    let {row, word, letter} = currentIndex;

    if (action === 'type') {
        const currentWordLength = rows[row][word].letters.length;
        if (letter < currentWordLength) {
            letter++;
        }
    } else if (action === 'backspace') {
        if (letter > 0) {
            letter--;
        } else if (word > 0) {
            word--;
            letter = rows[row][word].letters.length;
        } else if (row > 0) {
            row--;
            word = rows[row].length - 1;
            letter = rows[row][word].letters.length;
            setCurrentRowCutOff(prev => prev - 1);
        }
    } else if (action === 'space') {
        if (letter === 0) {
        //     do nothing
        } else if (word < rows[row].length - 1) {
            word++;
            letter = 0;
        } else if (row < rows.length - 1) {
            if (row !== 0) {
                setCurrentRowCutOff(prev => prev + 1);
            }
            row++;
            word = 0;
            letter = 0;
        }
    }

    setCurrentIndex({row, word, letter});
};