import { createContext, useState, useContext } from 'react';
import {getBackgroundColor} from "../utils/helpers";
import {similarity} from "../api/api";
import emojiDictionary from "emoji-dictionary";
import {getTodaysGame} from "../utils/dateUtil";
import {GAMES} from "../constants/simlr";

const SimlrContext = createContext();

export const useSimlrContext = () => useContext(SimlrContext);

export const SimlrProvider = ({ children }) => {
    const [guess, setGuess] = useState("");
    const [numGuesses, setNumGuesses] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())));
    const [selectedGame, setSelectedGame] = useState(getTodaysGame());
    const [word, setWord] = useState(selectedGame ? selectedGame.word : "");
    const [gameNumber, setGameNumber] = useState(selectedGame.id || 0);
    const [guesses, setGuesses] = useState([]);
    const [hintNum, setHintNum] = useState(0);
    const [latestGuess, setLatestGuess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showSettings, setShowSettings] = useState(false);

    const handleGuess = async () => {
        if (guess === "") {
            return;
        }
        if (guesses.some(g => g.guess === guess)) {
            setGuess("");
            const existingGuess = guesses.find(g => g.guess === guess);
            const {color, fill} = getBackgroundColor(existingGuess.similarity);
            setLatestGuess({...existingGuess, color, fill});
            return;
        }
        setLoading(true);

        const response = await similarity(word, guess);
        setNumGuesses(numGuesses + 1);

        const guessEmoji = emojiDictionary.getUnicode(guess) || " ";

        const newGuess = {guess, similarity: response.similarity, emoji: guessEmoji};

        const insertAtPosition = guesses.findIndex(g => g.similarity < newGuess.similarity);
        if (insertAtPosition === -1) {
            setGuesses([...guesses, newGuess]);
        } else {
            setGuesses([...guesses.slice(0, insertAtPosition), newGuess, ...guesses.slice(insertAtPosition)]);
        }
        const {color, fill} = getBackgroundColor(response.similarity);
        setLatestGuess({...newGuess, color, fill});
        setGuess("");
        setLoading(false);
    };

    const handleSpecificGameClick = (game) => {
        setSelectedDate(new Date(Date.UTC(new Date(game.date).getFullYear(), new Date(game.date).getMonth(), new Date(game.date).getDate())));
        setSelectedGame(game);
        setWord(game.word);
        setGuesses([]);
        setNumGuesses(0);
        setGameNumber(game.id);
        setHintNum(0);
        setLatestGuess(null);
        setShowSettings(false);
    }

    const handleNextGameClick = async () => {
        const nextGame = GAMES.find(game => game.id === gameNumber + 1);
        if (nextGame) {
            // if next game is ahead of today, don't allow
            if (new Date(nextGame.date) > new Date()) {
                console.error('Next game is ahead of today');
                return;
            }
            setSelectedDate(new Date(Date.UTC(new Date(nextGame.date).getFullYear(), new Date(nextGame.date).getMonth(), new Date(nextGame.date).getDate())));
            setSelectedGame(nextGame);
            setWord(nextGame.word);
            setGuesses([]);
            setNumGuesses(0);
            setGameNumber(nextGame.id);
            setHintNum(0);
            setLatestGuess(null);
            setShowSettings(false);
        } else {
            console.error('No next game found');
        }
    }

    const handlePreviousGameClick = async () => {
        const previousGame = GAMES.find(game => game.id === gameNumber - 1);
        if (previousGame) {
            setSelectedDate(new Date(Date.UTC(new Date(previousGame.date).getFullYear(), new Date(previousGame.date).getMonth(), new Date(previousGame.date).getDate())));
            setSelectedGame(previousGame);
            setWord(previousGame.word);
            setGuesses([]);
            setNumGuesses(0);
            setGameNumber(previousGame.id);
            setHintNum(0);
            setLatestGuess(null);
            setShowSettings(false);
        } else {
            console.error('No previous game found');
        }
    }

    const handleGetHint = async () => {
        if (hintNum >= selectedGame.hints.length) {
            return;
        }
        setLoading(true);

        const hintWord = selectedGame.hints[hintNum];
        const similarityResp = await similarity(word, hintWord);
        const newGuess = { guess: hintWord, similarity: similarityResp.similarity, emoji: emojiDictionary.getUnicode(hintWord) || " " };
        const { color, fill } = getBackgroundColor(similarityResp.similarity);

        if (guesses.some(g => g.guess === hintWord)) {
            setLatestGuess({ ...guesses.find(g => g.guess === hintWord), color, fill });
            setHintNum(hintNum + 1);
            setLoading(false);
            return;
        }

        setNumGuesses(numGuesses + 1);
        setHintNum(hintNum + 1);

        const insertAtPosition = guesses.findIndex(g => g.similarity < newGuess.similarity);
        if (insertAtPosition === -1) {
            setGuesses([...guesses, newGuess]);
        } else {
            setGuesses([...guesses.slice(0, insertAtPosition), newGuess, ...guesses.slice(insertAtPosition)]);
        }

        setLatestGuess({ ...newGuess, color, fill });
        setGuess("");
        setLoading(false);
    };

    const handleGiveUp = async () => {
        const newGuess = { guess: word, similarity: 1, emoji: emojiDictionary.getUnicode(word) || " " };
        setGuesses([newGuess, ...guesses]);
        setShowSettings(false);
    };

    return (
        <SimlrContext.Provider value={{
            guess,
            setGuess,
            numGuesses,
            setNumGuesses,
            selectedDate,
            setSelectedDate,
            selectedGame,
            setSelectedGame,
            word,
            setWord,
            gameNumber,
            setGameNumber,
            guesses,
            setGuesses,
            hintNum,
            setHintNum,
            latestGuess,
            setLatestGuess,
            loading,
            setLoading,
            showSettings,
            setShowSettings,
            handleGuess,
            handleSpecificGameClick,
            handleNextGameClick,
            handlePreviousGameClick,
            handleGetHint,
            handleGiveUp
        }}>
            {children}
        </SimlrContext.Provider>
    )
}