import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { TypinProvider } from "../contexts/TypinContext";
import BackHomeButton from "../components/molecules/BackHomeButton";
import Leaderboard from "../components/molecules/Typin/Leaderboard";
import ShareModal from "../components/molecules/Typin/ShareModal";
import Game from "../components/organisms/Typin/Game";
import ThemeButton from "../components/molecules/ThemeButton";
import CustomText from "../components/atoms/CustomText";

const ScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: ${props => props.theme.colors.background};
`;

const MobileText = styled(CustomText)`
    margin-top: 20px;
    max-width: 80%;
`;

const TypinScreen = () => {
    const { theme } = useTheme();

    return (
        <TypinProvider>
            <ScreenContainer theme={theme}>
                {window.innerWidth > 800 && <BackHomeButton textColor={theme.colors.text} />}
                <Game />
                <Leaderboard />
                <ShareModal />
                <ThemeButton />
            </ScreenContainer>
        </TypinProvider>
    );
};

export default TypinScreen;