import React from 'react';
import styled from 'styled-components';
import { useTheme } from "../../../contexts/ThemeContext";
import ResultCard from "./ResultCard";
import CustomText from "../../atoms/CustomText";

const ResultsContainer = styled.div`
    border-top: 1px solid ${props => props.theme.colors.border};
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px 20px;
    margin-top: 20px;
    width: 80%;
    align-items: stretch;
    padding-bottom: 100px;
    scroll-behavior: smooth;
    position: relative;
    z-index: 5;
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        width: 90%;
    }
`;


const PastResults = ({ results, onClick }) => {
    const { theme } = useTheme();

    return (
        <ResultsContainer theme={theme}>
            {results.map((result, index) => (
                <ResultCard key={index} result={result} onClick={onClick} />
            ))}
        </ResultsContainer>
    );
}

export default PastResults;