import React from "react";
import { useTheme } from "../../../contexts/ThemeContext";
import CustomText from "../../atoms/CustomText";
import styled from "styled-components";
import { FaRegQuestionCircle } from "react-icons/fa";
import {useSimlrContext} from "../../../contexts/SimlrContext";

const HowToContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-color: ${props => props.theme.simlrColors.cardBackground};
    padding: 10px 15px;
    border-radius: 10px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
`;

const HowToMaintenanceTitle = styled(CustomText)`
    font-size: 1.8em;
    color: ${props => props.theme.simlrColors.warning};
    opacity: 0.9;
`;

const HowToTitle = styled(CustomText)`
    font-size: 1.8em;
    color: ${props => props.theme.simlrColors.text};
    opacity: 0.9;
`;

const HowToText = styled(CustomText)`
    font-size: 1.3em;
    text-align: left;
    max-width: 500px;
    color: ${props => props.theme.simlrColors.text};
    opacity: 0.9;
`;

const HowToIcon = styled(FaRegQuestionCircle)`
    font-size: 2em;
    color: ${props => props.theme.simlrColors.text};
    opacity: 0.8;
    
    @media (max-width: 800px) {
        font-size: 1.8em;
    }
`;

const SimlrHowTo = () => {
    const { theme } = useTheme();
    const { guesses } = useSimlrContext();

    if (guesses.length > 0) {
        return null;
    }

    return (
        <HowToContainer theme={theme}>
            <HowToMaintenanceTitle type="semiBold" theme={theme}>Currently under maintenance.</HowToMaintenanceTitle>
            <Header>
                <HowToTitle type="semiBold" theme={theme}>How to Play</HowToTitle>
                <HowToIcon theme={theme}/>
            </Header>
            <HowToText type="medium" theme={theme}>
                Guess the secret word. You have unlimited guesses.
            </HowToText>
            <HowToText type="medium" theme={theme}>
                Each guess gets a score based on how similar it is to the secret word.
            </HowToText>
            <HowToText type="medium" theme={theme}>
                This score is calculated using word embeddings, which measure the similarity between words.
            </HowToText>
        </HowToContainer>
    );
}

export default SimlrHowTo;