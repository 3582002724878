import React, {useState} from "react";
import styled from "styled-components";
import { useTheme } from "../../../contexts/ThemeContext";
import { useScrambledContext } from "../../../contexts/ScrambledContext";
import CustomText from "../../atoms/CustomText";
import { FaPlus, FaMinus } from "react-icons/fa";

const MenuContainer = styled.div`
    position: absolute;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 15px;
`;

const FadedTextButton = styled(CustomText)`
    font-size: 1.1em;
    color: ${props => props.theme.palette1.grey};
    opacity: 0.5;
    font-weight: bold;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }
    
    &:active {
        transform: scale(0.95);
    }
`;

const HowToContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-width: 400px;
`;

const HowToText = styled(CustomText)`
    font-size: 1.2em;
    color: ${props => props.theme.palette1.grey};
    opacity: 0.8;
    text-align: center;
    max-width: 400px;
`;

const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;

const SettingsSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    max-width: 400px;
`;

const SettingsText = styled(CustomText)`
    font-size: 1.2em;
    color: ${props => props.theme.palette1.grey};
    opacity: 0.8;
    text-align: center;
    max-width: 400px;
`;

const SettingsButton = styled(CustomText)`
    font-size: 1.2em;
    opacity: ${props => props.selected ? 1 : 0.8};
    color: ${props => props.selected ? props.theme.palette1.primary : props.theme.palette1.grey};
    font-weight: bold;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }
    
    &:active {
        transform: scale(0.95);
    }
`;

const SettingsRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

const PlusIcon = styled(FaPlus)`
    font-size: 1.2em;
    color: ${props => props.theme.palette1.primary};
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    opacity: 0.6;
    
    &:hover {
        cursor: pointer;
        transform: scale(1.25);
    }
    
    &:active {
        transform: scale(0.95);
    }
`;

const MinusIcon = styled(FaMinus)`
    font-size: 1.2em;
    color: ${props => props.theme.palette1.text};
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    opacity: 0.6;
    
    &:hover {
        cursor: pointer;
        transform: scale(1.25);
    }
    
    &:active {
        transform: scale(0.95);
    }
`;

const HowToPlay = () => {
    const { theme } = useTheme();
    return (
        <HowToContainer>
            <HowToText theme={theme} type="medium">Swap letters to find the word</HowToText>
            <HowToText theme={theme} type="medium">Drag and drop or click on the letters to rearrange them.</HowToText>
        </HowToContainer>
    );

}

const Settings = () => {
    const { theme } = useTheme();
    const { difficulty, setDifficulty, attemptsLeft, setAttemptsLeft } = useScrambledContext();

    const handleAddAttempt = () => {
        setAttemptsLeft(attemptsLeft + 1);
    }

    const handleRemoveAttempt = () => {
        if (attemptsLeft > 1) {
            setAttemptsLeft(attemptsLeft - 1);
        }
    }

    return (
        <SettingsContainer>
            <SettingsSection>
                <SettingsButton onClick={() => setDifficulty('easy')} theme={theme} type="semiBold" size="small" selected={difficulty === 'easy'}>Easy</SettingsButton>
                <SettingsButton onClick={() => setDifficulty('medium')} theme={theme} type="semiBold" size="small" selected={difficulty === 'medium'}>Medium</SettingsButton>
                <SettingsButton onClick={() => setDifficulty('hard')} theme={theme} type="semiBold" size="small" selected={difficulty === 'hard'}>Hard</SettingsButton>
            </SettingsSection>
            <SettingsSection>
                <SettingsRow>
                    <MinusIcon theme={theme} onClick={handleRemoveAttempt} />
                    <SettingsText theme={theme} type="semiBold">{attemptsLeft} swaps</SettingsText>
                    <PlusIcon theme={theme} onClick={handleAddAttempt} />
                </SettingsRow>
            </SettingsSection>

        </SettingsContainer>
    );
}

const ScrambledMenu = () => {
    const { theme } = useTheme();
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [howToOpen, setHowToOpen] = useState(false);

    return (
        <MenuContainer theme={theme}>
            {!howToOpen && settingsOpen && <Settings />}
            {!howToOpen &&
                <FadedTextButton theme={theme} type="semiBold" onClick={() => setSettingsOpen(!settingsOpen)}>{settingsOpen ? "Close" : "Settings"}</FadedTextButton>}
            {!settingsOpen && howToOpen && <HowToPlay />}
            {!settingsOpen &&
                <FadedTextButton theme={theme} type="semiBold" onClick={() => setHowToOpen(!howToOpen)}>{howToOpen ? "Close" : "How to Play"}</FadedTextButton>}
        </MenuContainer>
    );
};


export default ScrambledMenu;