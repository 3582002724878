import React from 'react';
import styled from 'styled-components';
import { useTheme } from "../../../contexts/ThemeContext";
import CustomText from "../../atoms/CustomText";
import {getEmotionCounts} from "../../../utils/moodUtils";
import { Radar } from 'react-chartjs-2';
import 'chart.js/auto';
import { FaTwitter, FaReddit, FaRedditAlien, FaRegNewspaper } from "react-icons/fa6";

const ResultCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between; /* Change to space-between to evenly distribute space */
    gap: 10px;
    width: calc(100% - 32px);
    padding: 10px 15px;
    border-radius: 10px;
    background-color: ${props => props.theme.colors.cardBackground};
    border: 1px solid ${props => props.theme.colors.border};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    
    &:hover {
        cursor: pointer;
        transform: translateY(-2px);
    }
    
    @media (max-width: 768px) {
        padding: 13px;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
`;

const Title = styled(CustomText)`
    font-size: 1.6em;
    margin: 0;
    text-align: left;
`;

const Text = styled(CustomText)`
    font-size: 1em;
    text-align: center;
`;

const InfoText = styled(CustomText)`
    font-size: 1em;
    opacity: 0.6;
`;

const EmotionSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 3px;
`;

const EmotionList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0px 5px;
`;

const CountContainer = styled.div`
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const CountLabel = styled(CustomText)`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1em;
    background-color: ${props => props.theme.colors.primaryFaded};
    border: 1px solid ${props => props.theme.colors.primary};
    padding: 3px 8px;
    border-radius: 15px;
    color: ${props => props.theme.colors.primary};
`;

const NewsIcon = styled(FaRegNewspaper)`
    font-size: 1em;
`;

const TwitterIcon = styled(FaTwitter)`
    font-size: 1em;
`;

const RedditIcon = styled(FaRedditAlien)`
    font-size: 1em;
`;

const ResultCard = ({ result, onClick }) => {
    const { theme } = useTheme();
    const emotionCounts = getEmotionCounts(result);
    const tweetCount = result.tweets.length;
    const articleCount = result.articles.length;
    const redditPostCount = result.redditPosts.length;

    const data = {
        labels: Object.keys(emotionCounts),
        datasets: [
            {
                label: 'Emotion Counts',
                data: Object.values(emotionCounts),
                backgroundColor: theme.colors.primaryFade,
                borderColor: theme.colors.primary,
                borderWidth: 1,
            }
        ]
    };

    const options = {
        scales: {
            r: {
                angleLines: {
                    display: true,
                    color: theme.colors.border,
                },
                grid: {
                    color: theme.colors.border,
                },
                ticks: {
                    backdropColor: theme.colors.cardBackground,
                    color: theme.colors.text,
                    font: {
                        size: 12,
                    },
                },
                suggestedMin: 0,
                suggestedMax: Math.max(...Object.values(emotionCounts)) + 1
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
            }
        },
        maintainAspectRatio: false
    };

    const handleClick = () => {
        onClick(result);
    }

    return (
        <ResultCardContainer theme={theme} onClick={handleClick}>
            <Header>
                <Title type="regular">{result.search}</Title>
                <EmotionSection>
                    <InfoText type="regular">Emotion{Object.keys(emotionCounts).length > 1 ? 's' : ''} found</InfoText>
                    <EmotionList>
                        {Object.keys(emotionCounts).map((key, index) => (
                            // capitalize and add comma if not last item
                            <Text key={index} type="medium">{key.charAt(0).toUpperCase() + key.slice(1)}{index !== Object.keys(emotionCounts).length - 1 ? ',' : ''}</Text>
                        ))}
                    </EmotionList>
                </EmotionSection>
            </Header>
            {/*if there is only one emotion*/}
            {Object.keys(emotionCounts).length === 1 ? (
                <Text type="medium">The public is feeling {Object.keys(emotionCounts)[0].charAt(0).toUpperCase() + Object.keys(emotionCounts)[0].slice(1)}.</Text>
            ) : (
                <div style={{ width: '100%', height: '200px' }}>
                    <Radar data={data} options={options} />
                </div>
            )}
            <CountContainer>
                <CountLabel type="semiBold" theme={theme}><NewsIcon />{articleCount}</CountLabel>
                <CountLabel type="semiBold" theme={theme}><TwitterIcon />{tweetCount}</CountLabel>
                <CountLabel type="semiBold" theme={theme}><RedditIcon />{redditPostCount}</CountLabel>
            </CountContainer>
        </ResultCardContainer>
    );
}

export default ResultCard;