import React from 'react';
import styled, {css} from 'styled-components';
import { useTypinContext } from '../../../contexts/TypinContext';
import { useTheme } from '../../../contexts/ThemeContext';
import { FaArrowRotateRight } from "react-icons/fa6";

const iconStyles = css`
    position: absolute;
    bottom: -60px;
    font-size: 1.5em;
    opacity: 0.7;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: ${props => props.theme.colors.text};
  
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
    
    &:active {
        transform: scale(0.9);
    }
`;

const RestartIcon = styled(FaArrowRotateRight)`
    ${iconStyles}
`;

const Restart = () => {
    const { newGame, language } = useTypinContext();
    const { theme } = useTheme();

    return <RestartIcon onClick={() => newGame(language)} theme={theme} />;
}

export default Restart;