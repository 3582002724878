import React from "react";
import { useTheme } from "../../../contexts/ThemeContext";
import { getWeekdayAndMonthStringPlusDays } from "../../../utils/dateUtil";
import styled from "styled-components";
import { FaArrowRightLong } from "react-icons/fa6";
import { useSimlrContext } from "../../../contexts/SimlrContext";

const ButtonSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: absolute;
`;

const BottomLeftButtonSection = styled(ButtonSection)`
    left: 10px;
    bottom: 10px;
    
    @media (max-width: 800px) {
        left: 0px;
    }
`;

const BottomRightButtonSection = styled(ButtonSection)`
    right: 10px;
    bottom: 10px;
    
    @media (max-width: 800px) {
        right: 0px;
    }
`;

const LeftArrow = styled(FaArrowRightLong)`
    font-size: 1.5em;
    transform: rotate(180deg);
    transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
`;

const RightArrow = styled(FaArrowRightLong)`
    font-size: 1.5em;
    transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
`;

const Button = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    background-color: transparent;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2em;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    
    &:active {
        transform: scale(0.95);
    }
`;

const LeftButton = styled(Button)`
    color: ${props => props.theme.simlrColors.text};
    
    &:hover {
        transform: translateX(5px);
        ${LeftArrow} {
            transform: translateX(-15px) rotate(180deg);
        }
    }
    
    &:active {
        transform: scale(0.95);
    }
`;

const RightButton = styled(Button)`
    color: ${props => props.theme.simlrColors.text};

    &:hover {
        transform: translateX(-5px);
        ${RightArrow} {
            transform: translateX(15px);
        }
    }
    
    &:active {
        transform: scale(0.95);
    }
`;

const SimlrGameSelection = () => {
    const { theme } = useTheme();
    const { selectedDate, handlePreviousGameClick, handleNextGameClick } = useSimlrContext();

    return (
        <>
            <BottomLeftButtonSection>
                <LeftButton onClick={handlePreviousGameClick} theme={theme}>
                    <LeftArrow />
                    {getWeekdayAndMonthStringPlusDays(selectedDate, -1)}
                </LeftButton>
            </BottomLeftButtonSection>
            <BottomRightButtonSection>
                <RightButton onClick={handleNextGameClick} theme={theme}>
                    {getWeekdayAndMonthStringPlusDays(selectedDate, 1)}
                    <RightArrow />
                </RightButton>
            </BottomRightButtonSection>
        </>
    )
}

export default SimlrGameSelection;