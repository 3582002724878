import CustomInput from "../../atoms/CustomInput";
import {useSimlrContext} from "../../../contexts/SimlrContext";

const SimlrInput = () => {
    const { guess, setGuess, handleGuess } = useSimlrContext();

    const onGuess = (e) => {
        setGuess(e.target.value.toLowerCase());
    }

    return (
        <CustomInput
            value={guess}
            onChange={onGuess}
            placeholder="type a word"
            // onSubmit={handleGuess}
            style={{ marginTop: 0 }}
        />
    )
}

export default SimlrInput;