import React from "react";
import { useTheme } from "../contexts/ThemeContext";
import styled from "styled-components";
import { useSimlrContext } from "../contexts/SimlrContext";
import CustomText from "./atoms/CustomText";
import { presentPercentage } from "../utils/dateUtil";
import { getBackgroundColor } from "../utils/helpers";

const GuessesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 200px;
`;

const GuessContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 20px);
  background-color: #fff;
  border-radius: 15px;
  padding: 6px 8px;
  margin: 5px 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border: ${props => props.last ? '2px solid #000' : 'none'};
  position: relative;
  
  @media (max-width: 800px) {
    padding: 6px 6px;
  }
`;

const LastGuessContainer = styled(GuessContainer)`
    border: 2px solid #000;
    margin-bottom: 12px;
`;

const ScoreIndicator = styled.div`
  height: 100%;
  background-color: ${props => props.bgColor};
  width: ${props => props.fill}%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 13px;
  z-index: 0;
`;

const GuessContent = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
`;

const GuessText = styled(CustomText)`
    font-size: 1.3em;
    color: ${props => props.theme.simlrColors.text};
    
    @media (max-width: 800px) {
        font-size: 1.2em;
    }
`;

const GuessPercentage = styled(CustomText)`
    font-size: 1.2em;
    opacity: 0.7;
    color: ${props => props.theme.simlrColors.text};
    
    @media (max-width: 800px) {
        font-size: 1.1em;
    }
`;

const DecimalSpan = styled.span`
    font-size: .9em;
    opacity: 0.9;
    color: ${props => props.theme.simlrColors.text};
`;

const GuessList = () => {
    const { theme } = useTheme();
    const { guesses, latestGuess } = useSimlrContext();

    if (guesses.length === 0) {
        return null;
    }

    return (
        <GuessesContainer>
            {latestGuess && (
                <LastGuessContainer theme={theme}>
                    <ScoreIndicator bgColor={latestGuess.color} fill={latestGuess.fill} />
                    <GuessContent>
                        <GuessText type="medium" theme={theme}>{latestGuess.emoji} {latestGuess.guess}</GuessText>
                        <GuessPercentage type="semiBold" theme={theme}>
                            {presentPercentage(latestGuess.similarity )[0]}
                            <DecimalSpan theme={theme}>.{presentPercentage(latestGuess.similarity )[1]}</DecimalSpan>
                            %
                        </GuessPercentage>
                    </GuessContent>
                </LastGuessContainer>
            )}
            {guesses.map((guess, index) => {
                const { color, fill } = getBackgroundColor(guess.similarity);
                return (
                    <GuessContainer key={index} last={guess.guess === latestGuess?.guess} theme={theme}>
                        <ScoreIndicator bgColor={color} fill={fill} />
                        <GuessContent>
                            <GuessText theme={theme}>{guess.emoji} {guess.guess}</GuessText>
                            <GuessPercentage type="semiBold" theme={theme}>
                                {presentPercentage(guess.similarity )[0]}
                                <DecimalSpan theme={theme}>.{presentPercentage(guess.similarity )[1]}%</DecimalSpan>

                            </GuessPercentage>
                        </GuessContent>
                    </GuessContainer>
                );
            })}
        </GuessesContainer>
    )
}

export default GuessList;