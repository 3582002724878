import styled from "styled-components";
import CustomText from "../atoms/CustomText";
import { useTheme } from "../../contexts/ThemeContext";

const StyledFooter = styled.footer`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin-top: 1em;
    margin-bottom: .5em;
    
    position: fixed;
    bottom: 0;
    z-index: 1;
    
    a {
        color: #000;
        text-decoration: none;
        font-weight: bold;
    }
`;

const FooterText = styled(CustomText)`
    font-size: 1.2em;
    opacity: 0.5;
    color: ${props => props.theme.colors.text};
`;

const FooterTag = ({ textColor }) => {
    const { theme } = useTheme();
    return (
        <StyledFooter>
            <FooterText type="gloria" style={{ color: textColor }} theme={theme}>
                {/*{window.innerWidth > 800 ? "Created by " : "By "}*/}
                <a href="https://siii.fun" style={{ color: textColor }}>silas.fun</a>
            </FooterText>
        </StyledFooter>
    );
}

export default FooterTag;