import './assets/fonts/fonts.css';
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FontFaceObserver from "fontfaceobserver";
import SimlrScreen from "./screens/SimlrScreen";
import HomeScreen from "./screens/HomeScreen";
import {ThemeProvider} from "./contexts/ThemeContext";
import ScrambledScreen from "./screens/ScrambledScreen";
import MusicEraScreen from "./screens/MusicEraScreen";
import LifeStatsScreen from "./screens/LifeStatsScreen";
import TypinScreen from "./screens/TypinScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import PublicMoodScreen from "./screens/PublicMoodScreen";

function App() {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    const loadFonts = async () => {
      const fontObserver = new FontFaceObserver('Poppins');
      try {
        await fontObserver.load();
        setFontsLoaded(true);
      } catch (error) {
        console.error('Font loading error:', error);
      }
    };

    loadFonts();
  }, []);

  if (!fontsLoaded) {
    return null;
  }

  return (
      <Router>
        <ThemeProvider>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/mood" element={<PublicMoodScreen />} />
            <Route path="/typin" element={<TypinScreen />} />
            <Route path="/music-era" element={<MusicEraScreen />} />
            <Route path="/life-stats" element={<LifeStatsScreen />} />
            <Route path="/simlr" element={<SimlrScreen />} />
            <Route path="/scrambled" element={<ScrambledScreen />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
          </Routes>
        </ThemeProvider>
      </Router>
  );
}

export default App;