import React, { useState } from "react";
import styled from "styled-components";
import { useScrambledContext } from "../../../contexts/ScrambledContext";
import ScrambledLetterCard from "./ScrambledLetterCard";

const WordContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  margin: 10px 0;
`;

const ScrambledWord = () => {
    const { gameOver, scrambledWord, currentWord, setScrambledWord, decreaseAttempts  } = useScrambledContext();
    const [draggedIndex, setDraggedIndex] = useState(null);
    const [selectedLetterIndex, setSelectedLetterIndex] = useState(null);

    const swapLetters = (index1, index2) => {
        if (index1 === index2) {
            return;
        }
        if (gameOver) {
            return;
        }
        let arr = scrambledWord.split('');
        [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
        const newWord = arr.join('');
        setScrambledWord(newWord);
        decreaseAttempts();
    };

    const handleLetterClick = (index) => {
        if (selectedLetterIndex === null) {
            setSelectedLetterIndex(index);
        } else {
            swapLetters(selectedLetterIndex, index);
            setSelectedLetterIndex(null); // Reset selection
        }
    };

    const handleDragStart = (index) => (event) => {
        setDraggedIndex(index);
        // Optional: Customize drag image or data
    };

    const handleDrop = (index) => (event) => {
        event.preventDefault(); // Prevent default to allow drop
        if (draggedIndex !== index) {
            swapLetters(draggedIndex, index);
        }
    };

    const handleDragOver = (index) => (event) => {
        event.preventDefault(); // Necessary to allow drop
    };


    return (
        <WordContainer>
            {scrambledWord.split('').map((letter, index) => (
                <ScrambledLetterCard
                    key={index}
                    letter={letter}
                    isCorrect={currentWord[index] === letter}
                    draggable={true}
                    onDragStart={handleDragStart(index)}
                    onDragOver={handleDragOver(index)}
                    onDrop={handleDrop(index)}
                    onClick={() => handleLetterClick(index)}
                    isSelected={index === selectedLetterIndex}
                />
            ))}
        </WordContainer>
    );
};

export default ScrambledWord;