import React from 'react';
import styled from 'styled-components';
import CustomText from "../components/atoms/CustomText";
import { useTheme } from "../contexts/ThemeContext";
import { useNavigate } from "react-router-dom";

const ScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    background-color: ${props => props.theme.colors.background};
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    margin-top: 50px;
`;

const TopLeftContainer = styled.div`
    position: absolute;
    top: 0;
    left: 10px;
    padding: 10px;
    z-index: 100;
    
    @media (max-width: 800px) {
        top: 10px;
        right: 10px;
        left: auto;
    }
`;

const BackText = styled(CustomText)`
    font-size: 1.4em;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: ${props => props.theme.colors.text};
    
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }
    
    &:active {
        transform: scale(0.95);
    }
`;

const Title = styled(CustomText)`
    font-size: 2.5em;
    margin: 0;
    margin-bottom: 20px;
`;

const Text = styled(CustomText)`
    font-size: 1.2em;
    margin-bottom: 20px;
`;

const PrivacyPolicyScreen = () => {
    const { theme } = useTheme();
    const navigate = useNavigate();

    const goToHome = () => {
        navigate('/');
    }

    return (
        <ScreenContainer theme={theme}>
            <TopLeftContainer>
                <BackText onClick={goToHome} theme={theme} type="gloria">silas.fun</BackText>
            </TopLeftContainer>
            <Header>
                <Title theme={theme}>Privacy Policy</Title>
                <Text theme={theme}>
                    Siii.fun uses cookies and similar tracking technologies such as the Common ID cookie to provide its services.
                    The Common ID cookie stores a unique user id in the first party domain and is accessible to our ad partners.
                    This ID can then be utilized to improve user matching.
                </Text>
            </Header>
        </ScreenContainer>
    );
}

export default PrivacyPolicyScreen;