import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import { useTheme } from "../../../contexts/ThemeContext";
import CustomText from "../../atoms/CustomText";
import {Radar} from "react-chartjs-2";
import {getEmotionCounts} from "../../../utils/moodUtils";
import {FaRedditAlien, FaRegNewspaper, FaTwitter, FaXmark} from "react-icons/fa6";

const ResultModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
    background-color: ${props => props.theme.colors.background};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 60%;
    max-height: calc(80vh - 40px);
    overflow: auto;
    position: relative;
    
    @media (max-width: 1200px) {
        max-width: 80%;
    }
    
    @media (max-width: 768px) {
        max-width: 95%;
    }
`;

const PageSwitcher = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    position: relative;
`;

const Underline = styled.div`
    height: 2px;
    background-color: ${props => props.theme.colors.primary};
    position: absolute;
    bottom: 0px; // Ensure the underline is directly below the buttons
    transition: width 0.3s ease, left 0.3s ease; // Animate width and horizontal movement
`;

const PageButton = styled(CustomText)`
    font-size: 1em;
    cursor: pointer;
    opacity: ${props => (props.active ? "1" : "0.6")};
    transition: opacity 0.2s;
    
    &:hover {
        opacity: 1;
    }
`;

const Title = styled(CustomText)`
    font-size: 1.8em;
    margin: 0;
`;

const Text = styled(CustomText)`
    font-size: 1.2em;
    text-align: center;
`;

const InfoText = styled(CustomText)`
    font-size: 1em;
    opacity: 0.6;
`;

const CloseButton = styled(FaXmark)`
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5em;
    color: ${props => props.theme.colors.text};
    cursor: pointer;
    transition: color 0.2s;
    opacity: 0.6;
    
    &:hover {
        opacity: 0.8;
        transform: scale(1.05);
    }
    
    &:active {
        transform: scale(0.97);
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding-bottom: 10px;
    border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const EmotionSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 0px;
`;

const EmotionList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0px 5px;
    opacity: 0.9;
`;

const CountContainer = styled.div`
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const CountLabel = styled(CustomText)`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1em;
    background-color: ${props => props.theme.colors.primaryFaded};
    border: 1px solid ${props => props.theme.colors.primary};
    padding: 3px 8px;
    border-radius: 15px;
    color: ${props => props.theme.colors.primary};
`;

const NewsIcon = styled(FaRegNewspaper)`
    font-size: 1em;
`;

const TwitterIcon = styled(FaTwitter)`
    font-size: 1em;
`;

const RedditIcon = styled(FaRedditAlien)`
    font-size: 1em;
`;

const SourceContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    width: 100%;
    max-height: calc(80vh - 200px);
    overflow-y: auto;
    box-sizing: border-box;
    
    &::-webkit-scrollbar {
        width: 5px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.colors.primary};
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar-track {
        background-color: ${props => props.theme.colors.cardBackground};
        border-radius: 10px;
    }
`;

const SourceItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    padding: 5px;
    transition: all 0.3s ease;
    cursor: pointer;
    width: calc(100% - 10px);
`;

const SourceText = styled(CustomText)`
    font-size: 1em;
    text-align: left;
    white-space: normal;
    text-overflow: ellipsis;
    transition: width 0.3s ease;
`;

const SourceEmotion = styled(CustomText)`
    font-size: 1em;
    text-align: right;
    white-space: normal;
    overflow: visible;
    text-overflow: ellipsis;
    transition: width 0.3s ease;
    padding: 5px;
    border-radius: 5px;
    background-color: ${props => props.theme.colors.primaryFaded};
    color: ${props => props.theme.colors.primary};
`;

const Overview = ({ result }) => {
    const { theme } = useTheme();
    const emotionCounts = getEmotionCounts(result);
    const tweetCount = result.tweets.length;
    const articleCount = result.articles.length;
    const redditPostCount = result.redditPosts.length;

    const data = {
        labels: Object.keys(emotionCounts),
        datasets: [
            {
                label: 'Emotion Counts',
                data: Object.values(emotionCounts),
                backgroundColor: theme.colors.primaryFade,
                borderColor: theme.colors.primary,
                borderWidth: 1,
            }
        ]
    };

    const options = {
        scales: {
            r: {
                angleLines: {
                    display: true,
                    color: theme.colors.border,
                },
                grid: {
                    color: theme.colors.border,
                },
                ticks: {
                    backdropColor: theme.colors.cardBackground,
                    color: theme.colors.text,
                    font: {
                        size: 12,
                    },
                },
                suggestedMin: 0,
                suggestedMax: Math.max(...Object.values(emotionCounts)) + 1
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
            }
        },
        maintainAspectRatio: false
    };

    return (
        <>
            <EmotionSection>
                <InfoText type="regular">Emotion{Object.keys(emotionCounts).length > 1 ? 's' : ''} found</InfoText>
                <EmotionList>
                    {Object.keys(emotionCounts).map((key, index) => (
                        // capitalize and add comma if not last item
                        <Text key={index} type="medium">{key.charAt(0).toUpperCase() + key.slice(1)}{index !== Object.keys(emotionCounts).length - 1 ? ',' : ''}</Text>
                    ))}
                </EmotionList>
            </EmotionSection>
            {Object.keys(emotionCounts).length === 1 ? (
                <Text type="medium" style={{margin: "10px"}}></Text>
            ) : (
                <div style={{ width: '100%', height: '300px' }}>
                    <Radar data={data} options={options} />
                </div>
            )}
            <CountContainer>
                <CountLabel type="semiBold" theme={theme}><NewsIcon />{articleCount}</CountLabel>
                <CountLabel type="semiBold" theme={theme}><TwitterIcon />{tweetCount}</CountLabel>
                <CountLabel type="semiBold" theme={theme}><RedditIcon />{redditPostCount}</CountLabel>
            </CountContainer>
        </>
    );
}

const SourceView = ({ sources }) => {
    const { theme } = useTheme();

    const sourcesWithoutNeutral = sources.filter(source => source.label !== 'neutral');
    
    return (
        <SourceContainer theme={theme}>
            {sourcesWithoutNeutral.length > 0 && sourcesWithoutNeutral.map((source, index) => (
                <SourceItem key={index} theme={theme}>
                    <SourceText type="regular" theme={theme}>{source.text}</SourceText>
                    <SourceEmotion type="regular" theme={theme}>{source.label.charAt(0).toUpperCase() + source.label.slice(1)}</SourceEmotion>
                </SourceItem>
            ))}
            {sourcesWithoutNeutral.length === 0 && <Text type="regular">No sources found</Text>}
        </SourceContainer>
    );
}

const makePages = (result) => {
    const pages = ["Overview"];
    if (result.articles.filter(source => source.label !== 'neutral').length > 0) {
        pages.push("News");
    }
    if (result.tweets.filter(source => source.label !== 'neutral').length > 0) {
        pages.push("Tweets");
    }
    if (result.redditPosts.filter(source => source.label !== 'neutral').length > 0) {
        pages.push("Reddit");
    }
    return pages;
}

const ResultModal = ({ result, onClose }) => {
    const { theme } = useTheme();
    const [page, setPage] = useState("Overview");
    const pageButtonsRef = useRef([]);
    const [underlineWidth, setUnderlineWidth] = useState(0);
    const [underlineOffset, setUnderlineOffset] = useState(0);
    const [pages, setPages] = useState(makePages(result));

    useEffect(() => {
        // This function now directly updates underline's style to ensure changes are applied smoothly
        const updateUnderline = () => {
            const activeButton = pageButtonsRef.current[page];
            if (activeButton) {
                setUnderlineWidth(activeButton.offsetWidth);
                setUnderlineOffset(activeButton.offsetLeft);
            }
        };

        updateUnderline(); // Call on component mount and page change
    }, [page]); // Dependency on 'page' ensures underline moves when page changes

    const setPageAndUnderline = (newPage) => {
        setPage(newPage);
        const activeButton = pageButtonsRef.current[newPage];
        if (activeButton) {
            setUnderlineWidth(activeButton.offsetWidth);
            setUnderlineOffset(activeButton.offsetLeft);
        }
    };

    return (
        <ResultModalContainer>
            <ModalContent theme={theme}>
                <CloseButton onClick={onClose} theme={theme} />
                <Header theme={theme}>
                    <Title type="regular">{result.search}</Title>
                    <PageSwitcher>
                        {pages.map((item, index) => (
                            <PageButton
                                key={item}
                                active={page === item}
                                onClick={() => setPageAndUnderline(item)}
                                ref={el => pageButtonsRef.current[index] = el}
                                theme={theme}
                                type={page === item ? "semiBold" : "regular"}
                            >
                                {item}
                            </PageButton>
                        ))}
                        <Underline style={{ width: underlineWidth, left: underlineOffset }} theme={theme} />
                    </PageSwitcher>
                </Header>
                {page === "Overview" && <Overview result={result} />}
                {page === "News" && <SourceView sources={result.articles} />}
                {page === "Tweets" && <SourceView sources={result.tweets} />}
                {page === "Reddit" && <SourceView sources={result.redditPosts} />}
            </ModalContent>
        </ResultModalContainer>
    );
}

export default ResultModal;