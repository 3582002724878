export const sortResultsByDate = (results) => {
    return results.sort((a, b) => {
        const dateA = a.createdAt.toDate();
        const dateB = b.createdAt.toDate();
        return dateB - dateA;
    });
}

export const getEmotionCounts = (result) => {
    const emotionCounts = {};

    result.tweets.forEach(tweet => {
        if (emotionCounts[tweet.label]) {
            emotionCounts[tweet.label]++;
        } else {
            emotionCounts[tweet.label] = 1;
        }
    });

    result.articles.forEach(article => {
        if (emotionCounts[article.label]) {
            emotionCounts[article.label]++;
        } else {
            emotionCounts[article.label] = 1;
        }
    });

    result.redditPosts.forEach(post => {
        if (emotionCounts[post.label]) {
            emotionCounts[post.label]++;
        } else {
            emotionCounts[post.label] = 1;
        }
    });

    // delete neutral emotion
    delete emotionCounts.neutral;

    return emotionCounts;
}