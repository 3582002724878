import React, {useState} from "react";
import styled from "styled-components";
import CustomText from "../components/atoms/CustomText";
import { useTheme } from "../contexts/ThemeContext";
import CustomButton from "../components/atoms/CustomButton";
import CustomInput from "../components/atoms/CustomInput";
import { FaPlus, FaMinus } from "react-icons/fa";
import {createPlaylist} from "../api/api";
import SongCard from "../components/molecules/Music/SongCard";

const ScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: ${props => props.theme.musicEraColors.background};
    position: relative;
`;

const Header = styled.div`
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
`;

const Title = styled(CustomText)`
    font-size: 2.5em;
    color: ${props => props.theme.musicEraColors.text};
`;

const Subtitle = styled(CustomText)`
    font-size: 1.5em;
    color: ${props => props.theme.musicEraColors.text};
`;
const ErrorText = styled(CustomText)`
    font-size: 1.2em;
    color: ${props => props.theme.colors.error};
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    gap: 5px;
    margin-top: -50px;
`;

const InputButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

const IconButton = styled(CustomButton)`
    padding: 10px;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    background-color: ${props => props.theme.musicEraColors.primary};
    border: none;
    color: ${props => props.theme.musicEraColors.white};
    font-size: 1.5em;
    opacity: 0.8;
    display: flex;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    
    &:hover {
        transform: scale(1.05);
        background-color: ${props => props.theme.musicEraColors.brightPrimary};
    }
    
    &:active {
        transform: scale(0.95);
    }
`;

const PlusIcon = styled(FaPlus)`
    font-size: 1em;
`;

const MinusIcon = styled(FaMinus)`
    font-size: 1em;
`;

const Input = styled(CustomInput)`
    width: 100px;
    text-align: center;
    font-size: 2.5em;
    border: none;
    border-radius: 10px;
    background-color: ${props => props.theme.musicEraColors.primary};
    color: ${props => props.theme.musicEraColors.white};
    padding: 13px;
    margin: 0px;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    
    &:focus {
        outline: none;
        background-color: ${props => props.theme.musicEraColors.brightPrimary};
    }
    
    &::placeholder {
        color: ${props => props.theme.musicEraColors.white};
    }
`;

const InputButton = styled(CustomButton)`
    margin-top: 20px;
    background-color: ${props => props.theme.musicEraColors.text};
    color: ${props => props.theme.musicEraColors.white};
    border: none;
    
    &:hover {
        background-color: ${props => props.theme.musicEraColors.brightText};
    }
`;

const MusicEraScreen = () => {
    const { theme } = useTheme();
    const [year, setYear] = useState("2000");
    const [errorMessage, setErrorMessage] = useState(" ");
    const [playlist, setPlaylist] = useState([]);

    const handleCreatePlaylist = async () => {
        if (year === "") {
            setErrorMessageForSeconds("Please enter a year", 2);
            return;
        }
        if (parseInt(year) < 1800 || parseInt(year) > 2024) {
            setErrorMessageForSeconds("Must be between 1800 and 2024", 2);
            return;
        }

        const resp = await createPlaylist(year);
        setPlaylist(resp.playlist);
    }

    const handleEditYear = (e) => {
        if (isNaN(e.target.value)) {
            return;
        }
        if (e.target.value.length > 4) {
            return;
        }
        // once its at 4 characters, check if its a valid year
        if (e.target.value.length === 4) {
            if (parseInt(e.target.value) < 1800 || parseInt(e.target.value) > 2024) {
                setErrorMessageForSeconds("Must be between 1800 and 2024", 2);
                return;
            }
        }

        setYear(e.target.value);
    };

    const handleAddYear = () => {
        if (year === "") {
            setErrorMessageForSeconds("Please enter a year", 2);
            return;
        }
        if (parseInt(year) < 1800 || parseInt(year) > 2024) {
            setErrorMessageForSeconds("Must be between 1800 and 2024", 2);
            return;
        }

        setYear(parseInt(year) + 1);
    }

    const handleSubtractYear = () => {
        if (year === "") {
            setErrorMessageForSeconds("Please enter a year", 2);
            return;
        }
        if (parseInt(year) < 1800 || parseInt(year) > 2024) {
            setErrorMessageForSeconds("Must be between 1800 and 2024", 2);
            return;
        }

        setYear(parseInt(year) - 1);
    }

    const setErrorMessageForSeconds = (message, seconds) => {
        setErrorMessage(message);
        setTimeout(() => {
            setErrorMessage(" ");
        }, seconds * 1000);
    }

    return (
        <ScreenContainer theme={theme}>
            <Header>
                <Title type="bold" theme={theme}>Music Era</Title>
                <Subtitle theme={theme}>Enter a year to see the music of that era</Subtitle>
            </Header>

            <InputContainer>
                {errorMessage && <ErrorText theme={theme} type="medium">{errorMessage}</ErrorText>}
                <InputButtons>
                    <IconButton theme={theme} onClick={handleSubtractYear}><MinusIcon /></IconButton>
                    <Input
                        value={year}
                        onChange={handleEditYear}
                        theme={theme}
                        placeholder="2000"
                    />
                    <IconButton theme={theme} onClick={handleAddYear}><PlusIcon /></IconButton>
                </InputButtons>
                <InputButton theme={theme} onClick={handleCreatePlaylist}>Create Playlist</InputButton>
            </InputContainer>
            {playlist.length > 0 && (
                <div>
                    <CustomText theme={theme} type="bold">Playlist:</CustomText>
                    {playlist.map((song, index) => (
                        <SongCard key={index} song={song} />
                    ))}
                </div>
            )}
        </ScreenContainer>
    );
}

export default MusicEraScreen;