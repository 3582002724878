import React from "react";
import { useTheme } from "../../../contexts/ThemeContext";
import styled from "styled-components";
import CustomText from "../../atoms/CustomText";

const SongContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: ${props => props.theme.musicEraColors.background};
    border-radius: 10px;
`;

const SongArt = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 10px;
`;

const SongInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
`;

const SongTitle = styled(CustomText)`
    font-size: 1.5em;
    color: ${props => props.theme.musicEraColors.text};
`;

const SongArtist = styled(CustomText)`
    font-size: 1.2em;
    color: ${props => props.theme.musicEraColors.text};
    opacity: 0.8;
`;

const SongCard = ({ song }) => {
    const { theme } = useTheme();

    return (
        <SongContainer theme={theme}>
            <SongArt src={song.image} alt="Song Art" />
            <SongInfo>
                <SongTitle type="semiBold" theme={theme}>{song.title}</SongTitle>
                <SongArtist type="medium" theme={theme}>{song.artist}</SongArtist>
            </SongInfo>
        </SongContainer>
    );
}

export default SongCard;
