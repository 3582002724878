import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
`;

const LoaderWrapper = styled.div`
  width: 38px;
  height: 38px;
  background: ${({ theme }) => theme.colors.border};
  display: block;
  margin: 20px auto;
  position: relative;
  box-sizing: border-box;
  animation: ${rotateAnimation} 1s ease-in-out infinite reverse;
  
  &::before {
    content: '';
    box-sizing: border-box;
    left: 0;
    top: 0;
    transform: rotate(45deg);
    position: absolute;
    width: 38px;
    height: 38px;
    background: ${({ theme }) => theme.colors.border};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }
  
  &::after {
    content: '';
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    background: ${({ theme }) => theme.colors.background};
    transform: translate(-50%, -50%);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }
`;

const Loader = () => {
    const { theme } = useTheme();

    return <LoaderWrapper theme={theme}></LoaderWrapper>;
}

export default Loader;
