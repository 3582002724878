import React from 'react';
import styled, { keyframes } from 'styled-components';

const ldsEllipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const ldsEllipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(18px, 0);
  }
`;

const ldsEllipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const LoaderContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 5px;
`;

const Dot = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    left: 6px;
    animation: ${ldsEllipsis1} 0.6s infinite;
  }

  &:nth-child(2) {
    left: 6px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }

  &:nth-child(3) {
    left: 24px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }

  &:nth-child(4) {
    left: 42px;
    animation: ${ldsEllipsis3} 0.6s infinite;
  }
`;

const LoadingAnimation = () => (
  <LoaderContainer>
    <Dot></Dot>
    <Dot></Dot>
    <Dot></Dot>
    <Dot></Dot>
  </LoaderContainer>
);

export default LoadingAnimation;
