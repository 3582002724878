export const GAMES = [
    {
        id: 1,
        date: '2024-02-28',
        word: 'apple',
        hints: [
            "fruit",
            "computer",
            "juicy"
        ]
    },
    {
        id: 2,
        date: '2024-02-29',
        word: 'cheetah',
        hints: [
            "fast",
            "cat",
            "spots"
        ]
    },
    {
        id: 3,
        date: '2024-03-01',
        word: 'detective',
        hints: [
            "sherlock",
            "investigate",
            "mystery"
        ]
    },
    {
        id: 4,
        date: '2024-03-02',
        word: 'freedom',
        hints: [
            "liberty",
            "independence",
            "rights"
        ]
    },
    {
        id: 5,
        date: '2024-03-03',
        word: 'volcano',
        hints: [
            "lava",
            "eruption",
            "mountain"
        ]
    },
    {
        id: 6,
        date: '2024-03-04',
        word: 'love',
        hints: [
            "heart",
            "romance",
            "passion"
        ]
    },
    {
        id: 7,
        date: '2024-03-05',
        word: 'teacher',
        hints: [
            "school",
            "education",
            "instructor"
        ]
    },
    {
        id: 8,
        date: '2024-03-06',
        word: 'drone',
        hints: [
            "fly",
            "quadcopter",
            "helicopter",
        ]
    },
    {
        id: 9,
        date: '2024-03-07',
        word: 'penguin',
        hints: [
            "bird",
            "antarctica",
            "waddle"
        ]
    },
    {
        id: 10,
        date: '2024-03-08',
        word: 'computer',
        hints: [
            "laptop",
            "desktop",
            "keyboard"
        ]
    },
    {
        id: 11,
        date: '2024-03-09',
        word: 'house',
        hints: [
            "home",
            "building",
            "roof"
        ]

    },
    {
        id: 12,
        date: '2024-03-10',
        word: 'jungle',
        hints: [
            "forest",
            "trees",
            "wild"
        ]
    },
    {
        id: 13,
        date: '2024-03-11',
        word: 'mirror',
        hints: [
            "reflect",
            "glass",
            "image"
        ]
    },
    {
        id: 14,
        date: '2024-03-12',
        word: 'castle',
        hints: [
            "fortress",
            "king",
            "queen"
        ]
    },
    {
        id: 15,
        date: '2024-03-13',
        word: 'chocolate',
        hints: [
            "sweet",
            "candy",
            "milk"
        ]
    },
    {
        id: 16,
        date: '2024-03-14',
        word: 'pirate',
        hints: [
            "ship",
            "treasure",
            "captain"
        ]
    },
    {
        id: 17,
        date: '2024-03-15',
        word: 'dinosaur',
        hints: [
            "extinct",
            "reptile",
            "fossil"
        ]
    },
    {
        id: 18,
        date: '2024-03-16',
        word: 'knight',
        hints: [
            "armor",
            "sword",
            "medieval"
        ]
    },
    {
        id: 19,
        date: '2024-03-17',
        word: 'robot',
        hints: [
            "machine",
            "ai",
            "automation"
        ]
    },
    {
        id: 20,
        date: '2024-03-18',
        word: 'galaxy',
        hints: [
            "universe",
            "space",
            "milky way"
        ]
    },
    {
        id: 21,
        date: '2024-03-19',
        word: 'wizard',
        hints: [
            "magic",
            "sorcerer",
            "gandalf"
        ]
    },
    {
        id: 22,
        date: '2024-03-20',
        word: 'ocean',
        hints: [
            "pacific",
            "water",
            "sea"
        ]
    },
    {
        id: 23,
        date: '2024-03-21',
        word: 'library',
        hints: [
            "books",
            "reading",
            "quiet"
        ]
    },
    {
        id: 24,
        date: '2024-03-22',
        word: 'rainbow',
        hints: [
            "colorful",
            "rain",
            "prism"
        ]
    },
    {
        id: 25,
        date: '2024-03-23',
        word: 'mountain',
        hints: [
            "hill",
            "peak",
            "everest"
        ]
    },
    {
        id: 26,
        date: '2024-03-24',
        word: 'fire',
        hints: [
            "flame",
            "burn",
            "hot"
        ]
    },
    {
        id: 27,
        date: '2024-03-25',
        word: 'butterfly',
        hints: [
            "caterpillar",
            "wings",
            "metamorphosis"
        ]
    },
    {
        id: 28,
        date: '2024-03-26',
        word: 'guitar',
        hints: [
            "strings",
            "music",
            "rock"
        ]
    },
    {
        id: 29,
        date: '2024-03-27',
        word: 'dream',
        hints: [
            "sleep",
            "nightmare",
            "vision"
        ]
    },
    {
        id: 30,
        date: '2024-03-28',
        word: 'moon',
        hints: [
            "crater",
            "night",
            "lunar"
        ]
    },
    {
        id: 31,
        date: '2024-03-29',
        word: 'sun',
        hints: [
            "star",
            "light",
            "rays"
        ]
    },
    {
        id: 32,
        date: '2024-03-30',
        word: 'job',
        hints: [
            "engineer",
            "position",
            "work"
        ]
    },
    {
        id: 33,
        date: '2024-03-31',
        word: 'bicycle',
        hints: [
            "ride",
            "pedal",
            "bike"
        ]
    },
    {
        id: 34,
        date: '2024-04-01',
        word: 'dog',
        hints: [
            "bark",
            "pet",
            "puppy"
        ]
    },
    {
        id: 35,
        date: '2024-04-02',
        word: 'pencil',
        hints: [
            "write",
            "draw",
            "pen"
        ]
    },
    {
        id: 36,
        date: '2024-04-03',
        word: 'book',
        hints: [
            "pages",
            "read",
            "novel"
        ]
    },
    {
        id: 37,
        date: '2024-04-04',
        word: 'yoga',
        hints: [
            "stretch",
            "relax",
            "exercise",
        ]
    },
    {
        id: 38,
        date: '2024-04-05',
        word: 'pizza',
        hints: [
            "pie",
            "cheese",
            "pepperoni",
        ]
    },
    {
        id: 39,
        date: '2024-04-06',
        word: 'beach',
        hints: [
            "waves",
            "sand",
            "ocean"
        ]
    },
    {
        id: 40,
        date: '2024-04-07',
        word: 'star',
        hints: [
            "sky",
            "sun",
            "celestial"
        ]
    },
    {
        id: 41,
        date: '2024-04-08',
        word: 'puzzle',
        hints: [
            "pieces",
            "solve",
            "mystery"
        ]
    },
    {
        id: 42,
        date: '2024-04-09',
        word: 'car',
        hints: [
            "wheels",
            "drive",
            "vehicle",
        ]
    },
    {
        id: 43,
        date: '2024-04-10',
        word: 'phone',
        hints: [
            "text",
            "call",
            "mobile"
        ]
    },
    {
        id: 44,
        date: '2024-04-11',
        word: 'cafe',
        hints: [
            "lunch",
            "coffee",
            "bistro"
        ]
    },
    {
        id: 45,
        date: "2024-04-12",
        word: 'location',
        hints: [
            "home",
            "address",
            "locate"
        ]
    },
    {
        id: 46,
        date: "2024-04-13",
        word: 'garden',
        hints: [
            "grow",
            "plants",
            "flowers"
        ]
    },
    {
        id: 47,
        date: "2024-04-14",
        word: 'mall',
        hints: [
            "eatery",
            "grocery",
            "plaza"
        ]
    },
    {
        id: 48,
        date: "2024-04-15",
        word: 'bridge',
        hints: [
            "cross",
            "river",
            "structure"
        ]
    },
    {
        id: 49,
        date: "2024-04-16",
        word: 'lamp',
        hints: [
            "light",
            "shade",
            "bright"
        ]
    },
    {
        id: 50,
        date: "2024-04-17",
        word: 'camera',
        hints: [
            "photo",
            "lens",
            "shoot"
        ]
    },
    {
        id: 51,
        date: "2024-04-18",
        word: 'shoe',
        hints: [
            "sneaker",
            "sole",
            "footwear"
        ]
    },
    {
        id: 52,
        date: "2024-04-19",
        word: 'weather',
        hints: [
            "rain",
            "sunny",
            "forecast"
        ]
    },
    {
        id: 53,
        date: "2024-04-20",
        word: 'cat',
        hints: [
            "feline",
            "whiskers",
            "pet"
        ]
    },
    {
        id: 54,
        date: "2024-04-21",
        word: 'clock',
        hints: [
            "time",
            "watch",
            "tick"
        ]
    },
    {
        id: 55,
        date: "2024-04-22",
        word: 'fish',
        hints: [
            "aquatic",
            "swim",
            "gills"
        ]
    },
    {
        id: 56,
        date: "2024-04-23",
        word: 'cup',
        hints: [
            "mug",
            "drink",
            "handle"
        ]
    },
    {
        id: 57,
        date: "2024-04-24",
        word: 'tree',
        hints: [
            "wood",
            "leaf",
            "nature"
        ]
    },
    {
        id: 58,
        date: "2024-04-25",
        word: 'bird',
        hints: [
            "fly",
            "feather",
            "nest"
        ]
    },
    {
        id: 59,
        date: "2024-04-26",
        word: 'cloud',
        hints: [
            "sky",
            "white",
            "fluffy"
        ]
    },
    {
        id: 60,
        date: "2024-04-27",
        word: 'hat',
        hints: [
            "cap",
            "head",
            "wear"
        ]
    },
    {
        id: 61,
        date: "2024-04-28",
        word: 'river',
        hints: [
            "stream",
            "flow",
            "water"
        ]
    },
    {
        id: 62,
        date: "2024-04-29",
        word: 'mountain',
        hints: [
            "peak",
            "high",
            "climb"
        ]
    },
    {
        id: 63,
        date: "2024-04-30",
        word: 'train',
        hints: [
            "tracks",
            "rail",
            "locomotive"
        ]
    },
    {
        id: 64,
        date: "2024-05-01",
        word: 'balloon',
        hints: [
            "air",
            "float",
            "helium"
        ]
    },
    {
        id: 65,
        date: "2024-05-02",
        word: 'cherry',
        hints: [
            "fruit",
            "red",
            "pie"
        ]
    },
    {
        id: 66,
        date: '2024-05-03',
        word: 'key',
        hints: [
            "lock",
            "open",
            "door"
        ]
    },
    {
        id: 67,
        date: '2024-05-04',
        word: 'elephant',
        hints: [
            "large",
            "trunk",
            "tusks"
        ]
    },
    {
        id: 68,
        date: '2024-05-05',
        word: 'butter',
        hints: [
            "spread",
            "milk",
            "creamy"
        ]
    },
    {
        id: 69,
        date: '2024-05-06',
        word: 'squirrel',
        hints: [
            "tree",
            "nuts",
        ]
    },
    {
        id: 70,
        date: '2024-05-07',
        word: 'watch',
        hints: [
            "time",
            "wrist",
            "tick"
        ]
    },
    {
        id: 71,
        date: '2024-05-08',
        word: 'kite',
        hints: [
            "fly",
            "wind",
            "string"
        ]
    },
    {
        id: 72,
        date: '2024-05-09',
        word: 'spoon',
        hints: [
            "eat",
            "stir",
            "utensil"
        ]
    },
    {
        id: 73,
        date: '2024-05-10',
        word: 'robot',
        hints: [
            "automation",
            "metal",
            "program"
        ]
    },
    {
        id: 74,
        date: '2024-05-11',
        word: 'piano',
        hints: [
            "keys",
            "music",
            "play"
        ]
    },
    {
        id: 75,
        date: '2024-05-12',
        word: 'zebra',
        hints: [
            "stripes",
            "safari",
            "horse-like"
        ]
    },
    {
        id: 76,
        date: '2024-05-13',
        word: 'submarine',
        hints: [
            "underwater",
            "vessel",
            "dive"
        ]
    },
    {
        id: 77,
        date: '2024-05-14',
        word: 'helicopter',
        hints: [
            "rotor",
            "fly",
            "aircraft"
        ]
    },
    {
        id: 78,
        date: '2024-05-15',
        word: 'music',
        hints: [
            "melody",
            "notes",
            "instrument"
        ]
    },
    {
        id: 79,
        date: '2024-05-16',
        word: 'paintbrush',
        hints: [
            "art",
            "paint",
            "canvas"
        ]
    },
    {
        id: 80,
        date: '2024-05-17',
        word: 'grass',
        hints: [
            "green",
            "lawn",
            "field"
        ]
    },
    {
        id: 81,
        date: '2024-05-18',
        word: 'scissors',
        hints: [
            "cut",
            "blades",
            "craft"
        ]
    },
    {
        id: 82,
        date: '2024-05-19',
        word: 'banana',
        hints: [
            "yellow",
            "fruit",
            "apple"
        ]
    },
    {
        id: 83,
        date: '2024-05-20',
        word: 'umbrella',
        hints: [
            "rain",
            "wet",
            "shade"
        ]
    },
    {
        id: 84,
        date: '2024-05-21',
        word: 'radio',
        hints: [
            "broadcast",
            "music",
            "waves"
        ]
    },
    {
        id: 85,
        date: '2024-05-22',
        word: 'candle',
        hints: [
            "wax",
            "flame",
            "light"
        ]
    },
    {
        id: 86,
        date: '2024-05-23',
        word: 'glasses',
        hints: [
            "vision",
            "lenses",
            "frame"
        ]
    },
    {
        id: 87,
        date: '2024-05-24',
        word: 'rocket',
        hints: [
            "space",
            "launch",
            "NASA"
        ]
    },
    {
        id: 88,
        date: '2024-05-25',
        word: 'snow',
        hints: [
            "cold",
            "flakes",
            "winter"
        ]
    },
    {
        id: 89,
        date: '2024-05-26',
        word: 'bicycle',
        hints: [
            "ride",
            "pedal",
            "two wheels"
        ]
    },
    {
        id: 90,
        date: '2024-05-27',
        word: 'violin',
        hints: [
            "strings",
            "bow",
            "orchestra"
        ]
    },
    {
        id: 91,
        date: '2024-05-28',
        word: 'whale',
        hints: [
            "ocean",
            "large",
            "mammal"
        ]
    },
    {
        id: 92,
        date: '2024-05-29',
        word: 'cactus',
        hints: [
            "desert",
            "spikes",
            "plant"
        ]
    },
    {
        id: 93,
        date: '2024-05-30',
        word: 'pyramid',
        hints: [
            "egypt",
            "ancient",
            "triangle"
        ]
    },
    {
        id: 94,
        date: '2024-05-31',
        word: 'chess',
        hints: [
            "board",
            "king",
            "strategy"
        ]
    },
    {
        id: 95,
        date: '2024-06-01',
        word: 'chef',
        hints: [
            "cook",
            "kitchen",
            "restaurant"
        ]
    },
    {
        id: 96,
        date: '2024-06-02',
        word: 'island',
        hints: [
            "water",
            "land",
            "beach"
        ]
    },
    {
        id: 97,
        date: '2024-06-03',
        word: 'saxophone',
        hints: [
            "music",
            "jazz",
            "brass"
        ]
    },
    {
        id: 98,
        date: '2024-06-04',
        word: 'lemon',
        hints: [
            "sour",
            "yellow",
            "citrus"
        ]
    },
    {
        id: 99,
        date: '2024-06-05',
        word: 'kangaroo',
        hints: [
            "pouch",
            "australia",
            "jump"
        ]
    },
    {
        id: 100,
        date: '2024-06-06',
        word: 'helicopter',
        hints: [
            "rotor",
            "fly",
            "aircraft"
        ]
    },
    {
        id: 101,
        date: '2024-06-07',
        word: 'music',
        hints: [
            "melody",
            "notes",
            "instrument"
        ]
    },
    {
        id: 102,
        date: '2024-06-08',
        word: 'paintbrush',
        hints: [
            "art",
            "paint",
            "canvas"
        ]
    },
    {
        id: 103,
        date: '2024-06-09',
        word: 'grass',
        hints: [
            "green",
            "lawn",
            "field"
        ]
    },
    {
        id: 104,
        date: '2024-06-10',
        word: 'scissors',
        hints: [
            "cut",
            "blades",
            "craft"
        ]
    },
    {
        id: 105,
        date: '2024-06-11',
        word: 'banana',
        hints: [
            "yellow",
            "fruit",
            "apple"
        ]
    },
    {
        id: 106,
        date: '2024-06-12',
        word: 'umbrella',
        hints: [
            "rain",
            "wet",
            "shade"
        ]
    },
    {
        id: 107,
        date: '2024-06-13',
        word: 'radio',
        hints: [
            "broadcast",
            "music",
            "waves"
        ]
    },
    {
        id: 108,
        date: '2024-06-14',
        word: 'candle',
        hints: [
            "wax",
            "flame",
            "light"
        ]
    },
    {
        id: 109,
        date: '2024-06-15',
        word: 'glasses',
        hints: [
            "vision",
            "lenses",
            "frame"
        ]
    },
    {
        id: 110,
        date: '2024-06-16',
        word: 'rocket',
        hints: [
            "space",
            "launch",
            "NASA"
        ]
    },
    {
        id: 111,
        date: '2024-06-17',
        word: 'snow',
        hints: [
            "cold",
            "flakes",
            "winter",
        ]
    },
    {
        id: 112,
        date: '2024-06-18',
        word: 'bicycle',
        hints: [
            "ride",
            "pedal",
            "two wheels"
        ]
    },
    {
        id: 113,
        date: '2024-06-19',
        word: 'violin',
        hints: [
            "strings",
            "bow",
            "orchestra"
        ]
    },
    {
        id: 114,
        date: '2024-06-20',
        word: 'whale',
        hints: [
            "ocean",
            "large",
            "mammal"
        ]
    },
    {
        id: 115,
        date: '2024-06-21',
        word: 'cactus',
        hints: [
            "desert",
            "spikes",
            "plant"
        ]
    },
    {
        id: 116,
        date: '2024-06-22',
        word: 'pyramid',
        hints: [
            "egypt",
            "ancient",
            "triangle"
        ]
    },
    {
        id: 117,
        date: '2024-06-23',
        word: 'chess',
        hints: [
            "board",
            "king",
            "strategy"
        ]
    },
    {
        id: 118,
        date: '2024-06-24',
        word: 'chef',
        hints: [
            "cook",
            "kitchen",
            "restaurant"
        ]
    },
    {
        id: 119,
        date: '2024-06-25',
        word: 'island',
        hints: [
            "water",
            "land",
            "beach"
        ]
    },
    {
        id: 120,
        date: '2024-06-26',
        word: 'saxophone',
        hints: [
            "music",
            "jazz",
            "brass"
        ]
    },
    {
        id: 121,
        date: '2024-06-27',
        word: 'lemon',
        hints: [
            "sour",
            "yellow",
            "citrus"
        ]
    },
    {
        id: 122,
        date: '2024-06-28',
        word: 'kangaroo',
        hints: [
            "pouch",
            "australia",
            "jump"
        ],
    },
    {
        id: 123,
        date: '2024-06-29',
        word: 'astronaut',
        hints: [
            "space",
            "nasa",
            "orbit"
        ],
    },
    {
        id: 124,
        date: '2024-06-30',
        word: 'diamond',
        hints: [
            "gem",
            "sparkle",
            "jewelry"
        ]
    },
    {
        id: 125,
        date: '2024-07-01',
        word: 'fountain',
        hints: [
            "water",
            "park",
            "statue"
        ]
    },
    {
        id: 126,
        date: '2024-07-02',
        word: 'microscope',
        hints: [
            "science",
            "lab",
            "lens"
        ]
    },
    {
        id: 127,
        date: '2024-07-03',
        word: 'umbrella',
        hints: [
            "rain",
            "wet",
            "shade"
        ]
    },
    {
        id: 128,
        date: '2024-07-04',
        word: 'peacock',
        hints: [
            "bird",
            "feathers",
            "colorful"
        ]
    },
    {
        id: 129,
        date: '2024-07-05',
        word: 'radio',
        hints: [
            "broadcast",
            "music",
            "waves"
        ]
    },
    {
        id: 130,
        date: '2024-07-06',
        word: 'candle',
        hints: [
            "wax",
            "flame",
            "light"
        ]
    },
    {
        id: 131,
        date: '2024-07-07',
        word: 'lighthouse',
        hints: [
            "beacon",
            "coast",
            "light"
        ]
    },
    {
        id: 132,
        date: '2024-07-08',
        word: 'puzzle',
        hints: [
            "pieces",
            "solve",
            "jigsaw"
        ]
    },
    {
        id: 133,
        date: '2024-07-09',
        word: 'strawberry',
        hints: [
            "fruit",
            "red",
            "berry"
        ]
    },
    {
        id: 134,
        date: '2024-07-10',
        word: 'waterfall',
        hints: [
            "cascade",
            "river",
            "nature"
        ]
    },
    {
        id: 135,
        date: '2024-07-11',
        word: 'telescope',
        hints: [
            "stars",
            "observe",
            "astronomy"
        ]
    },
    {
        id: 136,
        date: '2024-07-12',
        word: 'butterfly',
        hints: [
            "wings",
            "insect",
            "metamorphosis"
        ]
    },
    {
        id: 137,
        date: '2024-07-13',
        word: 'volcano',
        hints: [
            "lava",
            "eruption",
            "mountain"
        ]
    },
    {
        id: 138,
        date: '2024-07-14',
        word: 'galaxy',
        hints: [
            "stars",
            "universe",
            "space"
        ]
    },
    {
        id: 139,
        date: '2024-07-15',
        word: 'eagle',
        hints: [
            "bird",
            "soar",
            "feathers"
        ]
    },
    {
        id: 140,
        date: '2024-07-16',
        word: 'mask',
        hints: [
            "costume",
            "face",
            "disguise"
        ]
    },
    {
        id: 141,
        date: '2024-07-17',
        word: 'tornado',
        hints: [
            "storm",
            "wind",
            "twister"
        ]
    },
    {
        id: 142,
        date: '2024-07-18',
        word: 'giraffe',
        hints: [
            "tall",
            "animal",
            "spots"
        ]
    },
    {
        id: 143,
        date: '2024-07-19',
        word: 'mushroom',
        hints: [
            "fungus",
            "cap",
            "forest"
        ]
    },
    {
        id: 144,
        date: '2024-07-20',
        word: 'crystal',
        hints: [
            "gem",
            "clear",
            "mineral"
        ]
    },
    {
        id: 145,
        date: '2024-07-21',
        word: 'satellite',
        hints: [
            "orbit",
            "space",
            "communication"
        ]
    },
    {
        id: 146,
        date: '2024-07-22',
        word: 'chocolate',
        hints: [
            "sweet",
            "candy",
            "cocoa"
        ]
    },
    {
        id: 147,
        date: '2024-07-23',
        word: 'submarine',
        hints: [
            "underwater",
            "navy",
            "dive"
        ]
    },
    {
        id: 148,
        date: '2024-07-24',
        word: 'dolphin',
        hints: [
            "ocean",
            "smart",
            "mammal"
        ]
    },
    {
        id: 149,
        date: '2024-07-25',
        word: 'mountain',
        hints: [
            "peak",
            "high",
            "climb"
        ]
    },
    {
        id: 150,
        date: '2024-07-26',
        word: 'rainbow',
        hints: [
            "color",
            "sky",
            "spectrum"
        ]
    },
    {
        id: 151,
        date: '2024-07-27',
        word: 'volcano',
        hints: [
            "lava",
            "eruption",
            "mountain"
        ]
    },
    {
        id: 152,
        date: '2024-07-28',
        word: 'airplane',
        hints: [
            "fly",
            "wing",
            "sky"
        ]
    },
    {
        id: 153,
        date: '2024-07-29',
        word: 'pyramid',
        hints: [
            "egypt",
            "triangle",
            "ancient"
        ]
    },
    {
        id: 154,
        date: '2024-07-30',
        word: 'hurricane',
        hints: [
            "storm",
            "wind",
            "rain"
        ]
    },
    {
        id: 155,
        date: '2024-07-31',
        word: 'robot',
        hints: [
            "automation",
            "ai",
            "machine"
        ]
    },
    {
        id: 156,
        date: '2024-08-01',
        word: 'galaxy',
        hints: [
            "stars",
            "universe",
            "cosmos"
        ]
    },
    {
        id: 157,
        date: '2024-08-02',
        word: 'panda',
        hints: [
            "bear",
            "black and white",
            "bamboo"
        ]
    },
    {
        id: 158,
        date: '2024-08-03',
        word: 'rocket',
        hints: [
            "space",
            "launch",
            "NASA"
        ]
    },
    {
        id: 159,
        date: '2024-08-04',
        word: 'vampire',
        hints: [
            "blood",
            "fangs",
            "undead"
        ]
    },
    {
        id: 160,
        date: '2024-08-05',
        word: 'mermaid',
        hints: [
            "myth",
            "sea",
            "fish"
        ]
    },
    {
        id: 161,
        date: '2024-08-06',
        word: 'igloo',
        hints: [
            "snow",
            "ice",
            "house"
        ]
    },
    {
        id: 162,
        date: '2024-08-07',
        word: 'forest',
        hints: [
            "trees",
            "wild",
            "woods"
        ]
    },
    {
        id: 163,
        date: '2024-08-08',
        word: 'chameleon',
        hints: [
            "lizard",
            "color",
            "camouflage"
        ]
    },
    {
        id: 164,
        date: '2024-08-09',
        word: 'spaceship',
        hints: [
            "space",
            "astronaut",
            "travel"
        ]
    },
    {
        id: 165,
        date: '2024-08-10',
        word: 'helicopter',
        hints: [
            "rotor",
            "fly",
            "aircraft"
        ]
    },
    {
        id: 166,
        date: '2024-08-11',
        word: 'magnet',
        hints: [
            "metal",
            "attract",
            "polar"
        ]
    },
    {
        id: 167,
        date: '2024-08-12',
        word: 'waterfall',
        hints: [
            "cascade",
            "river",
            "nature"
        ]
    },
    {
        id: 168,
        date: '2024-08-13',
        word: 'asteroid',
        hints: [
            "space",
            "rock",
            "orbit"
        ]
    },
    {
        id: 169,
        date: '2024-08-14',
        word: 'phoenix',
        hints: [
            "fire",
            "myth",
            "rebirth"
        ]
    },
    {
        id: 170,
        date: '2024-08-15',
        word: 'ballet',
        hints: [
            "dance",
            "theater",
            "grace"
        ]
    },
    {
        id: 171,
        date: '2024-08-16',
        word: 'canyon',
        hints: [
            "valley",
            "cliff",
            "gorge"
        ]
    },
    {
        id: 172,
        date: '2024-08-17',
        word: 'windmill',
        hints: [
            "blades",
            "energy",
            "rotate"
        ]
    },
    {
        id: 173,
        date: '2024-08-18',
        word: 'pencil',
        hints: [
            "write",
            "eraser",
            "lead"
        ]
    },
    {
        id: 174,
        date: '2024-08-19',
        word: 'crocodile',
        hints: [
            "reptile",
            "teeth",
            "swamp"
        ]
    },
    {
        id: 175,
        date: '2024-08-20',
        word: 'escalator',
        hints: [
            "stairs",
            "moving",
            "mall"
        ]
    },
    {
        id: 176,
        date: '2024-08-21',
        word: 'cruise',
        hints: [
            "ship",
            "vacation",
            "sea"
        ]
    },
    {
        id: 177,
        date: '2024-08-22',
        word: 'desert',
        hints: [
            "sand",
            "dry",
            "oasis"
        ]
    },
    {
        id: 178,
        date: '2024-08-23',
        word: 'mountain',
        hints: [
            "peak",
            "high",
            "climb"
        ]
    },
    {
        id: 179,
        date: '2024-08-24',
        word: 'polar bear',
        hints: [
            "arctic",
            "white",
            "bear"
        ]
    },
    {
        id: 180,
        date: '2024-08-25',
        word: 'tiger',
        hints: [
            "stripes",
            "jungle",
            "cat"
        ]
    },
    {
        id: 181,
        date: '2024-08-26',
        word: 'fireworks',
        hints: [
            "explosion",
            "celebration",
            "sky"
        ]
    },
    {
        id: 182,
        date: '2024-08-27',
        word: 'lighthouse',
        hints: [
            "beacon",
            "coast",
            "navigation"
        ]
    },
    {
        id: 183,
        date: '2024-08-28',
        word: 'origami',
        hints: [
            "fold",
            "paper",
            "art"
        ]
    },
    {
        id: 184,
        date: '2024-08-29',
        word: 'tornado',
        hints: [
            "twister",
            "funnel",
            "storm"
        ]
    },
    {
        id: 185,
        date: '2024-08-30',
        word: 'oasis',
        hints: [
            "desert",
            "water",
            "refuge"
        ]
    },
    {
        id: 186,
        date: '2024-08-31',
        word: 'jellyfish',
        hints: [
            "tentacles",
            "ocean",
            "sting"
        ]
    },
    {
        id: 187,
        date: '2024-09-01',
        word: 'bonsai',
        hints: [
            "miniature",
            "tree",
            "prune"
        ]
    },
    {
        id: 188,
        date: '2024-09-02',
        word: 'fossil',
        hints: [
            "ancient",
            "remains",
            "dinosaur"
        ]
    },
    {
        id: 189,
        date: '2024-09-03',
        word: 'kaleidoscope',
        hints: [
            "patterns",
            "colors",
            "tube"
        ]
    },
    {
        id: 190,
        date: '2024-09-04',
        word: 'carousel',
        hints: [
            "horses",
            "ride",
            "circular"
        ]
    },
    {
        id: 191,
        date: '2024-09-05',
        word: 'acrobat',
        hints: [
            "circus",
            "flexible",
            "balance"
        ]
    },
    {
        id: 192,
        date: '2024-09-06',
        word: 'labyrinth',
        hints: [
            "maze",
            "complex",
            "puzzle"
        ]
    },
    {
        id: 193,
        date: '2024-09-07',
        word: 'flamingo',
        hints: [
            "pink",
            "bird",
            "lagoon"
        ]
    },
    {
        id: 194,
        date: '2024-09-08',
        word: 'avalanche',
        hints: [
            "snow",
            "slide",
            "mountain"
        ]
    },
    {
        id: 195,
        date: '2024-09-09',
        word: 'gladiator',
        hints: [
            "arena",
            "warrior",
            "ancient"
        ]
    },
    {
        id: 196,
        date: '2024-09-10',
        word: 'zeppelin',
        hints: [
            "airship",
            "balloon",
            "fly"
        ]
    },
    {
        id: 197,
        date: '2024-09-11',
        word: 'catapult',
        hints: [
            "launch",
            "medieval",
            "projectile"
        ]
    },
    {
        id: 198,
        date: '2024-09-12',
        word: 'mirage',
        hints: [
            "illusion",
            "desert",
            "vision"
        ]
    },
    {
        id: 199,
        date: '2024-09-13',
        word: 'gargoyle',
        hints: [
            "statue",
            "grotesque",
            "building"
        ]
    },
    {
        id: 200,
        date: '2024-09-14',
        word: 'sushi',
        hints: [
            "raw",
            "fish",
            "rice"
        ]
    },
    {
        id: 201,
        date: '2024-09-15',
        word: 'boomerang',
        hints: [
            "return",
            "throw",
            "curve"
        ]
    },
    {
        id: 202,
        date: '2024-09-16',
        word: 'quicksand',
        hints: [
            "sink",
            "trap",
            "danger"
        ]
    },
    {
        id: 203,
        date: '2024-09-17',
        word: 'scarecrow',
        hints: [
            "field",
            "straw",
            "birds"
        ]
    },
    {
        id: 204,
        date: '2024-09-18',
        word: 'gondola',
        hints: [
            "venice",
            "boat",
            "canal"
        ]
    },
    {
        id: 205,
        date: '2024-09-19',
        word: 'harpoon',
        hints: [
            "spear",
            "whale",
            "throw"
        ]
    },
    {
        id: 206,
        date: '2024-09-20',
        word: 'jukebox',
        hints: [
            "music",
            "coin",
            "selection"
        ]
    },
    {
        id: 207,
        date: '2024-09-21',
        word: 'parachute',
        hints: [
            "fall",
            "sky",
            "safety"
        ]
    },
    {
        id: 208,
        date: '2024-09-22',
        word: 'hologram',
        hints: [
            "3D",
            "projection",
            "light"
        ]
    },
    {
        id: 209,
        date: '2024-09-23',
        word: 'catacombs',
        hints: [
            "underground",
            "tunnels",
            "burial"
        ]
    },
    {
        id: 210,
        date: '2024-09-24',
        word: 'guillotine',
        hints: [
            "execution",
            "blade",
            "french"
        ]
    },
    {
        id: 211,
        date: '2024-09-25',
        word: 'firefly',
        hints: [
            "glow",
            "insect",
            "night"
        ]
    },
    {
        id: 212,
        date: '2024-09-26',
        word: 'sphinx',
        hints: [
            "egypt",
            "riddle",
            "statue"
        ]
    },
    {
        id: 213,
        date: '2024-09-27',
        word: 'origami',
        hints: [
            "paper",
            "fold",
            "japanese"
        ]
    },
    {
        id: 214,
        date: '2024-09-28',
        word: 'samurai',
        hints: [
            "warrior",
            "sword",
            "japan"
        ]
    },
    {
        id: 215,
        date: '2024-09-29',
        word: 'monsoon',
        hints: [
            "rain",
            "season",
            "tropical"
        ]
    },
    {
        id: 216,
        date: '2024-09-30',
        word: 'sundial',
        hints: [
            "time",
            "shadow",
            "ancient"
        ]
    },
    {
        id: 217,
        date: '2024-10-01',
        word: 'trebuchet',
        hints: [
            "catapult",
            "siege",
            "medieval"
        ]
    },
    {
        id: 218,
        date: '2024-10-02',
        word: 'obelisk',
        hints: [
            "monument",
            "stone",
            "tall"
        ]
    },
    {
        id: 219,
        date: '2024-10-03',
        word: 'bamboo',
        hints: [
            "plant",
            "panda",
            "fast-growing"
        ]
    },
    {
        id: 220,
        date: '2024-10-04',
        word: 'chakra',
        hints: [
            "energy",
            "spiritual",
            "body"
        ]
    },
    {
        id: 221,
        date: '2024-10-05',
        word: 'didgeridoo',
        hints: [
            "instrument",
            "australian",
            "wind"
        ]
    }
];
