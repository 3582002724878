const AVERAGE_LIFE_EXPECTANCY_YEARS = 80;
const BREATHS_PER_MINUTE = 14;
const BREATHS_PER_SECOND = BREATHS_PER_MINUTE / 60;
const AIR_LITERS_INHALED_PER_MINUTE = 7.6;
const AIR_LITERS_INHALED_PER_SECOND = AIR_LITERS_INHALED_PER_MINUTE / 60;
const LITERS_IN_OLYMPIC_SWIMMING_POOL = 2_500_000;
const FARTS_PER_DAY = 13;
const HEARTBEATS_PER_MINUTE = 75;
const BLOOD_PUMPED_LITERS_PER_MINUTE = 5;
const HEARTBEATS_PER_SECOND = HEARTBEATS_PER_MINUTE / 60;
const BLOOD_PUMPED_LITERS_PER_SECOND = BLOOD_PUMPED_LITERS_PER_MINUTE / 60;
const BLINKS_PER_MINUTE = 15;
const BLINKS_PER_SECOND = BLINKS_PER_MINUTE / 60;
const PERCENTAGE_OF_LIFE_ASLEEP = .2917;
const AVERAGE_STEPS_PER_DAY = 4000;
const AVERAGE_STEPS_PER_MINUTE = AVERAGE_STEPS_PER_DAY / 1440;
const WORDS_PER_DAY = 15000;
const WORDS_PER_MINUTE = WORDS_PER_DAY / 1440;
const WORDS_PER_BOOK = 75000;
const AVERAGE_NUMBER_OF_DREAMS_PER_NIGHT = 4;
const AVERAGE_LAUGH_PER_DAY = 15;
const AVERAGE_CRY_PER_YEAR_MALE = 10;
const AVERAGE_CRY_PER_YEAR_FEMALE = 40;
const AVERAGE_WATER_USAGE_PER_DAY_LITERS = 3800;
const EARTH_MILES_PER_DAY_TO_SUN = 1_600_000;
const EARTH_MILER_PER_MINUTE_TO_SUN = EARTH_MILES_PER_DAY_TO_SUN / 1440;
const EARTH_MILES_PER_SECOND_TO_SUN = EARTH_MILER_PER_MINUTE_TO_SUN / 60;
const EARTH_MILES_PER_DAY_TO_MILKY_WAY = 12_000_000;
const EARTH_MILES_PER_MINUTE_TO_MILKY_WAY = EARTH_MILES_PER_DAY_TO_MILKY_WAY / 1440;
const EARTH_MILES_PER_SECOND_TO_MILKY_WAY = EARTH_MILES_PER_MINUTE_TO_MILKY_WAY / 60;
const AVERAGE_METEOR_SHOWERS_PER_YEAR = 30;
const LUNAR_ECLIPSES_PER_YEAR = 2;
const SOLAR_ECLIPSES_PER_YEAR = 2.5;
const FULL_MOON_CYCLE_DAYS = 29.53;
const TIDAL_CYCLES_PER_DAY = 2;
const GREAT_PACIFIC_GARBAGE_PATCH_SIZE_IN_KM_LATEST = 2605000; // Apr 4 2024 (Great Pacific Garbage Patch doubles every decade) was found in 1977, so 47 years so 55,350 km^2 per year
const GREAT_PACIFIC_GARBAGE_PATCH_GROWTH_PER_DAY = 55350 / 365.25;
const GLOBAL_FOOD_WASTE_PER_YEAR = 1_300_000_000; // 1.3 billion tons of food is wasted each year
const GLOBAL_FOOD_WASTE_PER_DAY = GLOBAL_FOOD_WASTE_PER_YEAR / 365.25;
const GLOBAL_FOOD_WASTE_PER_SECOND = GLOBAL_FOOD_WASTE_PER_DAY / 86400;
const AMERICAN_YEARLY_CARBON_EMISSIONS_TONS = 16; // 16 tons of CO2 per person per year
const AMERICAN_DAILY_CARBON_EMISSIONS_TONS = AMERICAN_YEARLY_CARBON_EMISSIONS_TONS / 365.25;
const AMERICAN_SECONDLY_CARBON_EMISSIONS_TONS = AMERICAN_DAILY_CARBON_EMISSIONS_TONS / 86400;
const AVERAGE_TREE_ABSORBS_CO2_POUNDS_PER_YEAR = 48; // 48 pounds of CO2 per year
const AVERAGE_TREE_ABSORBS_CO2_TONS_PER_YEAR = AVERAGE_TREE_ABSORBS_CO2_POUNDS_PER_YEAR / 2000;
const AVERAGE_GB_OF_DATA_USED_PER_MONTH = 256.4; // 256.4 GB of data used per month
const AVERAGE_GB_OF_DATA_USED_PER_DAY = AVERAGE_GB_OF_DATA_USED_PER_MONTH / 30.44;
const AVERAGE_GB_OF_DATA_USED_PER_SECOND = AVERAGE_GB_OF_DATA_USED_PER_DAY / 86400;
// https://climate.nasa.gov/vital-signs/global-temperature/?intent=121

export const MONTHS = [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december'
];

export const validMonth = (month) => {
    return MONTHS.includes(month.toLowerCase());
}

const convertMonthToNumber = (month) => {
    return MONTHS.indexOf(month.toLowerCase());
}

export const validateAndSetBirthDate = (day, month, year, setBirthDate) => {
    const date = new Date(year, convertMonthToNumber(month), day);
    if (date.toString() === 'Invalid Date') {
        return false;
    }
    setBirthDate(date);
    return true;
}

export const calculateLifeStats = (birthDate) => {
    const now = new Date();
    const secondsAlive = Math.floor((now - birthDate) / 1000);
    const minutesAlive = Math.floor(secondsAlive / 60);
    const hoursAlive = Math.floor(minutesAlive / 60);
    const daysAlive = Math.floor(hoursAlive / 24);
    const yearsAlive = Math.floor(daysAlive / 365);
    const percentageOfLife = yearsAlive / AVERAGE_LIFE_EXPECTANCY_YEARS;

    const hoursAsleep = Math.floor(daysAlive * PERCENTAGE_OF_LIFE_ASLEEP * 24);
    const breathsTaken = Math.floor(secondsAlive * BREATHS_PER_SECOND);
    const airInhaledLiters = Math.floor(secondsAlive * AIR_LITERS_INHALED_PER_SECOND);
    const olympicSwimmingPoolsInhaled = Math.floor(airInhaledLiters / LITERS_IN_OLYMPIC_SWIMMING_POOL);
    const fartsPassed = Math.floor(daysAlive * FARTS_PER_DAY);
    const heartbeats = Math.floor(secondsAlive * HEARTBEATS_PER_SECOND);
    const bloodPumped = Math.floor(secondsAlive * BLOOD_PUMPED_LITERS_PER_SECOND);
    const olympicSwimmingPoolsBloodPumped = Math.floor(bloodPumped / LITERS_IN_OLYMPIC_SWIMMING_POOL);
    const blinks = Math.floor(secondsAlive * BLINKS_PER_SECOND);
    const stepsTaken = Math.floor(minutesAlive * AVERAGE_STEPS_PER_MINUTE);
    const wordsSpoken = Math.floor(minutesAlive * WORDS_PER_MINUTE);
    const booksSpoken = Math.floor(wordsSpoken / WORDS_PER_BOOK);
    const dreamsHad = Math.floor(daysAlive * AVERAGE_NUMBER_OF_DREAMS_PER_NIGHT);
    const laughsHad = Math.floor(daysAlive * AVERAGE_LAUGH_PER_DAY);
    const criesHadMale = Math.floor(yearsAlive * AVERAGE_CRY_PER_YEAR_MALE);
    const criesHadFemale = Math.floor(yearsAlive * AVERAGE_CRY_PER_YEAR_FEMALE);

    const waterUsedLiters = Math.floor(daysAlive * AVERAGE_WATER_USAGE_PER_DAY_LITERS);

    const temperatureChangeSinceBirth = findTemperatureChange(birthDate);
    const globalPopulationChangeSinceBirth = findPopulationChange(birthDate);
    const foodWastedSinceBirth = Math.floor(secondsAlive * GLOBAL_FOOD_WASTE_PER_SECOND);
    const garbagePatchSizeGrowthSinceBirth = Math.min(GREAT_PACIFIC_GARBAGE_PATCH_SIZE_IN_KM_LATEST, Math.floor(daysAlive * GREAT_PACIFIC_GARBAGE_PATCH_GROWTH_PER_DAY));

    const earthMilesToSun = Math.floor(secondsAlive * EARTH_MILES_PER_SECOND_TO_SUN);
    const earthMilesToMilkyWay = Math.floor(secondsAlive * EARTH_MILES_PER_SECOND_TO_MILKY_WAY);
    const meteorShowersSeen = Math.floor(yearsAlive * AVERAGE_METEOR_SHOWERS_PER_YEAR);
    const lunarEclipsesSeen = Math.floor(yearsAlive * LUNAR_ECLIPSES_PER_YEAR);
    const solarEclipsesSeen = Math.floor(yearsAlive * SOLAR_ECLIPSES_PER_YEAR);
    const fullMoonCycles = Math.floor(daysAlive / FULL_MOON_CYCLE_DAYS);
    const tidalCycles = Math.floor(daysAlive * TIDAL_CYCLES_PER_DAY);

    const carbonEmissionsTons = Math.floor(secondsAlive * AMERICAN_SECONDLY_CARBON_EMISSIONS_TONS);
    const treesNeededToOffsetCarbon = Math.floor(carbonEmissionsTons / AVERAGE_TREE_ABSORBS_CO2_TONS_PER_YEAR);

    const dataUsed = Math.floor(secondsAlive * AVERAGE_GB_OF_DATA_USED_PER_SECOND);

    return {
        secondsAlive,
        minutesAlive,
        hoursAlive,
        daysAlive,
        yearsAlive,
        percentageOfLife,
        hoursAsleep,

        breathsTaken,
        airInhaledLiters,
        olympicSwimmingPoolsInhaled,
        fartsPassed,
        heartbeats,
        bloodPumped,
        olympicSwimmingPoolsBloodPumped,
        blinks,
        stepsTaken,
        wordsSpoken,
        booksSpoken,
        dreamsHad,
        laughsHad,
        criesHadMale,
        criesHadFemale,

        waterUsedLiters,
        foodWastedSinceBirth,
        garbagePatchSizeGrowthSinceBirth,
        temperatureChangeSinceBirth,
        globalPopulationChangeSinceBirth,
        earthMilesToSun,
        earthMilesToMilkyWay,
        meteorShowersSeen,
        lunarEclipsesSeen,
        solarEclipsesSeen,
        fullMoonCycles,
        tidalCycles,

        carbonEmissionsTons,
        treesNeededToOffsetCarbon,
        dataUsed
    };
};

const globalPopulationDict = {

}

const temperateLowessDict = {
    1880: -0.09,
    1881: -0.13,
    1882: -0.16,
    1883: -0.20,
    1884: -0.24,
    1885: -0.26,
    1886: -0.27,
    1887: -0.27,
    1888: -0.26,
    1889: -0.25,
    1890: -0.25,
    1891: -0.25,
    1892: -0.26,
    1893: -0.26,
    1894: -0.24,
    1895: -0.22,
    1896: -0.20,
    1897: -0.18,
    1898: -0.16,
    1899: -0.17,
    1900: -0.20,
    1901: -0.23,
    1902: -0.25,
    1903: -0.28,
    1904: -0.30,
    1905: -0.33,
    1906: -0.35,
    1907: -0.37,
    1908: -0.39,
    1909: -0.40,
    1910: -0.41,
    1911: -0.38,
    1912: -0.35,
    1913: -0.32,
    1914: -0.30,
    1915: -0.29,
    1916: -0.29,
    1917: -0.29,
    1918: -0.29,
    1919: -0.29,
    1920: -0.27,
    1921: -0.26,
    1922: -0.25,
    1923: -0.24,
    1924: -0.23,
    1925: -0.22,
    1926: -0.22,
    1927: -0.21,
    1928: -0.20,
    1929: -0.19,
    1930: -0.19,
    1931: -0.18,
    1932: -0.18,
    1933: -0.17,
    1934: -0.15,
    1935: -0.14,
    1936: -0.10,
    1937: -0.06,
    1938: -0.01,
    1939: 0.03,
    1940: 0.07,
    1941: 0.09,
    1942: 0.11,
    1943: 0.10,
    1944: 0.08,
    1945: 0.05,
    1946: 0.01,
    1947: -0.03,
    1948: -0.07,
    1949: -0.08,
    1950: -0.07,
    1951: -0.07,
    1952: -0.07,
    1953: -0.07,
    1954: -0.06,
    1955: -0.06,
    1956: -0.05,
    1957: -0.04,
    1958: -0.01,
    1959: 0.01,
    1960: 0.03,
    1961: 0.01,
    1962: -0.01,
    1963: -0.03,
    1964: -0.04,
    1965: -0.05,
    1966: -0.06,
    1967: -0.05,
    1968: -0.03,
    1969: -0.02,
    1970: -0.00,
    1971: 0.00,
    1972: 0.00,
    1973: -0.00,
    1974: 0.01,
    1975: 0.02,
    1976: 0.04,
    1977: 0.07,
    1978: 0.12,
    1979: 0.16,
    1980: 0.20,
    1981: 0.21,
    1982: 0.22,
    1983: 0.21,
    1984: 0.21,
    1985: 0.22,
    1986: 0.24,
    1987: 0.27,
    1988: 0.31,
    1989: 0.33,
    1990: 0.33,
    1991: 0.33,
    1992: 0.33,
    1993: 0.33,
    1994: 0.34,
    1995: 0.37,
    1996: 0.40,
    1997: 0.42,
    1998: 0.44,
    1999: 0.47,
    2000: 0.50,
    2001: 0.52,
    2002: 0.55,
    2003: 0.58,
    2004: 0.61,
    2005: 0.62,
    2006: 0.62,
    2007: 0.63,
    2008: 0.64,
    2009: 0.64,
    2010: 0.65,
    2011: 0.66,
    2012: 0.70,
    2013: 0.74,
    2014: 0.79,
    2015: 0.83,
    2016: 0.87,
    2017: 0.91,
    2018: 0.93,
    2019: 0.93,
    2020: 0.95,
    2021: 0.97,
    2022: 0.99,
    2023: 1.01,
    2024: 1.03
};

const findTemperatureChange = (birthDate) => {
    const birthYear = birthDate.getFullYear();
    const currentYear = new Date().getFullYear();

    const change = temperateLowessDict[currentYear] - temperateLowessDict[birthYear];

    return Math.round(change * 100) / 100;
};

const findPopulationChange = (birthDate) => {
    const birthYear = birthDate.getFullYear();
    const currentYear = new Date().getFullYear();

    const change = globalPopulationDict[currentYear] - globalPopulationDict[birthYear];

    return Math.round(change);
}
