import styled from "styled-components";
import CustomText from "../atoms/CustomText";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";

const TopLeftContainer = styled.div`
    position: absolute;
    top: 0;
    left: 10px;
    padding: 10px;
`;

const BackText = styled(CustomText)`
    font-size: 1.4em;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: ${props => props.theme.colors.text};
    
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }
    
    &:active {
        transform: scale(0.95);
    }
`;

const BackHomeButton = ({ textColor }) => {
    const { theme } = useTheme();
    const navigate = useNavigate();

    const goToHome = () => {
        navigate('/');
    }

    return (
        <TopLeftContainer>
            <BackText type="gloria" onClick={goToHome} theme={theme} style={{ color: textColor }}>
                silas.fun
            </BackText>
        </TopLeftContainer>
    )
}

export default BackHomeButton;