import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://silasfun-backend-i7wihjyhfq-uc.a.run.app',
});
const embeddingsApiClient = axios.create({
    baseURL: 'https://embeddings-api-scwx464r5q-ue.a.run.app',
});
const openaiClient = axios.create({
    baseURL: 'https://api.openai.com/v1',
    headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    },
});

export const createPlaylist = async (year) => {
    try {
        const response = await apiClient.get(`/playlist?year=${year}`);
        return response.data;
    } catch (error) {
        console.error('Error while creating playlist', error);
        return null;
    }
}

export const openaiCompletion = async (model, messages) => {
    try {
        const response = await openaiClient.post('/chat/completions', {
            model: model,
            messages: messages,
        });
        return response.data.choices[0].message.content;
    } catch (error) {
        console.error('Error while fetching OpenAI completion', error);
        return null;
    }
}

export const wordArithmetic = async (additions, subtractions) => {
    try {
        const response = await embeddingsApiClient.post('/arithmetic', { additions, subtractions });
        return response.data;
    } catch (error) {
        console.error('Error while fetching word arithmetic', error);
        return null;
    }

}

export const similarity = async (word1, word2) => {
    try {
        const response = await embeddingsApiClient.get(`/similarity?word1=${word1}&word2=${word2}`);
        return response.data;
    } catch (error) {
        console.error('Error while fetching similarity', error);
        return null;
    }
};

export const getPublicEmotions = async (search) => {
    try {
        const response = await apiClient.post('/public-emotions', { search });
        return response.data;
    } catch (error) {
        console.error('Error while fetching emotions', error);
        return null;
    }
}