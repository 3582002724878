import React from 'react';
import styled from 'styled-components';

const LeaderboardContainer = styled.section`
    width: 100%;
`;

const Leaderboard = () => (
    <LeaderboardContainer>
        {/* Leaderboard content goes here */}
    </LeaderboardContainer>
);

export default Leaderboard;