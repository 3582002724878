import {createContext, useContext, useEffect, useState} from 'react';
import {TIME_SETTINGS} from "../constants/typin";
import {
    calculateAccuracy,
    calculateWPM,
    getRandomWords, getRowLength,
    splitWordsIntoRows,
    updateCurrentIndex
} from "../utils/typinUtils";

const TypinContext = createContext();

export const useTypinContext = () => useContext(TypinContext);

export const TypinProvider = ({ children }) => {
    const [language, setLanguage] = useState('english');

    const randomWords = getRandomWords(language, 300);
    const maxCharsPerRow = getRowLength();
    const initialRows = splitWordsIntoRows(randomWords, maxCharsPerRow);
    const [words, setWords] = useState(randomWords);
    const [rows, setRows] = useState(initialRows);

    const [currentRowCutOff, setCurrentRowCutOff] = useState(0);
    const [currentIndex, setCurrentIndex] = useState({row: 0, word: 0, letter: 0});

    const [startTime, setStartTime] = useState(null);
    const [timeSetting, setTimeSetting] = useState(TIME_SETTINGS[1]);
    const [timer, setTimer] = useState(timeSetting);

    const [gameState, setGameState] = useState('waiting');

    const [accuracy, setAccuracy] = useState(0);
    const [wpm, setWPM] = useState(0);

    const [viewMode, setViewMode] = useState('rows'); // rows or line

    useEffect(() => {
        if (gameState === 'playing') {
            if (timer > 0) {
                const interval = setInterval(() => {
                    setTimer(prev => prev - 1);
                }, 1000);
                return () => clearInterval(interval);
            } else {
                endGame();
            }
        }
    }, [timer, gameState]);

    const newGame = (lang) => {
        const randomWords = getRandomWords(lang, 300);
        setWords(randomWords);
        const initialRows = splitWordsIntoRows(randomWords, maxCharsPerRow);
        setRows(initialRows);
        setGameState('waiting');
        setStartTime(null);
        setTimer(timeSetting);
        setWPM(0);
        setAccuracy(0);
        setCurrentIndex({row: 0, word: 0, letter: 0});
        setCurrentRowCutOff(0);
    };

    const endGame = () => {
        setGameState('ended');
        const endTime = Date.now();
        const wpm = calculateWPM(rows, startTime, endTime);
        const accuracy = calculateAccuracy(rows);
        setWPM(wpm);
        setAccuracy(accuracy);
    };

    const startGame = () => {
        setGameState('playing');
        setStartTime(Date.now());
        setTimer(timeSetting);
    };

    const updateTimeSetting = (time) => {
        setTimeSetting(time);
        setTimer(time);
    };

    const updateLanguage = (lang) => {
        setLanguage(lang);
        newGame(lang);
    };

    const toggleViewMode = () => {
        setViewMode(prev => prev === 'rows' ? 'line' : 'rows');
    };

    const handleInputChange = (e) => {
        if (gameState !== 'playing') {
            startGame();
        }

        if (!isInputValid(e)) {
            return;
        }

        const inputKey = e.key;

        switch (inputKey) {
            case 'Backspace':
                handleBackspace();
                break;
            case ' ':
                handleSpace();
                break;
            default:
                handleLetter(e);
        }
    };

    const handleSpace = () => {
        // Can't space at start of word
        if (currentIndex.letter === 0) {
            return;
        }

        const newRows = JSON.parse(JSON.stringify(rows));
        const {row: currentRow, word: currentWord, letter: currentLetter} = currentIndex;

        updateCurrentIndex({action: 'space', rows: newRows, currentIndex, setCurrentIndex, setCurrentRowCutOff });

        newRows[currentRow][currentWord].letters.forEach((letter, index) => {
            if (index >= currentLetter && letter.state === 'untyped') {
                letter.state = 'incorrect';
            }
        });

        setRows(newRows);
    };

    const handleBackspace = () => {
        // Can't backspace at start of game
        if (currentIndex.row === 0 && currentIndex.word === 0 && currentIndex.letter === 0) {
            return;
        }

        const newRows = JSON.parse(JSON.stringify(rows));
        const {row: currentRow, word: currentWord, letter: currentLetter} = currentIndex;

        // Backspace the current letter
        if (currentLetter === 0) {
            newRows[currentRow][currentWord].letters[currentLetter].state = 'untyped';
        } else {
            newRows[currentRow][currentWord].letters[currentLetter - 1].state = 'untyped';
        }

        updateCurrentIndex({action: 'backspace', rows: newRows, currentIndex, setCurrentIndex, setCurrentRowCutOff});

        setRows(newRows);
    };

    const handleLetter = (e) => {
        const newRows = JSON.parse(JSON.stringify(rows));
        const {row: currentRow, word: currentWord, letter: currentLetter} = currentIndex;

        let currentWordObj = newRows[currentRow] ? newRows[currentRow][currentWord] : null;
        let currentLetterObj = currentWordObj ? currentWordObj.letters[currentLetter] : null;

        if (!currentWordObj || !currentLetterObj) {
            console.error("Current word or letter object is undefined.");
            return;
        }

        if (e.key === currentLetterObj.char) {
            newRows[currentRow][currentWord].letters[currentLetter].state = 'correct';
        } else {
            newRows[currentRow][currentWord].letters[currentLetter].state = 'incorrect';
        }

        updateCurrentIndex({action: 'type', rows: newRows, currentIndex, setCurrentIndex, setCurrentRowCutOff});

        // Update state with newRows
        setRows(newRows);
    };

    const isInputValid = (e) => {
        if (e.metaKey || e.ctrlKey || e.altKey) {
            return false;
        }

        if (!e.key || (!e.key.match(/^[a-zA-Z\s]$/) && e.key !== 'Backspace' && e.key !== '-' && e.key !== "(" && e.key !== ")" && e.key !== "." && e.key !== "+" && e.key !== "_")) {
            return false;
        }

        return true;
    };

    return (
        <TypinContext.Provider value={{
            language,
            words,
            rows,
            currentRowCutOff,
            currentIndex,
            timer,
            gameState,
            timeSetting,
            wpm,
            accuracy,
            viewMode,
            handleInputChange,
            updateTimeSetting,
            newGame,
            endGame,
            startGame,
            updateLanguage,
            toggleViewMode
        }}>
            {children}
        </TypinContext.Provider>
    );
}