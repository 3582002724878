import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { useTheme } from "../contexts/ThemeContext";
import Input from "../components/molecules/Mood/Input";
import PastResults from "../components/molecules/Mood/PastResults";
import ResultModal from "../components/molecules/Mood/ResultModal";
import { getPublicEmotions } from "../api/api";
import { addEmotionResult, subscribeToEmotions } from "../services/firebase/firestore";
import { sortResultsByDate } from "../utils/moodUtils";
import CustomText from "../components/atoms/CustomText";
import Loader from "../components/molecules/Loader";
import BackHomeButton from "../components/molecules/BackHomeButton";

const ScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    background-color: ${props => props.theme.colors.background};
    width: 100%;
`;

const Title = styled(CustomText)`
    font-size: 1.3em;
    margin: 0;
    margin-top: 40px;
    opacity: 0.8;
    
    @media (max-width: 768px) {
        margin-top: 20px;
    }
`;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const LoadingText = styled(CustomText)`
    font-size: 1.2em;
    margin: 0;
    opacity: 0.8;
`;

const PasteResultsText = styled(CustomText)`
    font-size: 1.1em;
    margin: 0;
    margin-top: 10px;
    margin-bottom: -15px;
    opacity: 0.5;
    align-self: flex-start;
    margin-left: 10%;

    @media (max-width: 768px) {
        margin-left: 5%;
    }
`;

const LoadingComponent = ({ loading }) => {
    const [text, setText] = useState("Gathering news articles");
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (!loading) {
            setText("Gathering news articles");
            setCurrentIndex(0);
            return;
        }

        const texts = ["Gathering news articles", "Gathering tweets", "Gathering Reddit posts", "Analyzing emotions"];

        const interval = setInterval(() => {
            if (currentIndex < texts.length - 1) {
                setCurrentIndex(currentIndex + 1);
                setText(texts[currentIndex + 1]);
            }
        }, Math.floor(Math.random() * 1500) + 1000);

        return () => clearInterval(interval);
    }, [loading, currentIndex]);

    if (!loading) return null;

    return (
        <LoadingContainer>
            {loading && <Loader />}
            <LoadingText type="medium">{text}</LoadingText>
        </LoadingContainer>
    );
}

const PublicMoodScreen = () => {
    const { theme } = useTheme();
    const [modalContent, setModalContent] = useState({});
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const unsubscribe = subscribeToEmotions((querySnapshot) => {
            const emotions = [];
            querySnapshot.forEach((doc) => {
                emotions.push(doc.data());
            });
            const sortedEmotions = sortResultsByDate(emotions);
            setResults(sortedEmotions);
        });

        return () => unsubscribe();
    }, []);

    const handleSearch = async (text) => {
        setLoading(true);
        setError(null);

        try {
            const response = await getPublicEmotions(text);
            const result = {
                search: text,
                tweets: response.emotions.tweets,
                articles: response.emotions.articles,
                redditPosts: response.emotions.reddit_posts,
                createdAt: new Date()
            };
            await addEmotionResult(result);
        } catch (error) {
            setError(error);
            console.error("Error adding document: ", error);
        }

        setLoading(false);
    };

    const handleResultClick = (result) => {
        setModalContent(result);
    };

    return (
        <ScreenContainer theme={theme}>
            {window.innerWidth > 800 && <BackHomeButton textColor={theme.colors.text} />}
            <Title theme={theme} type="medium">Enter a query to see how the public is feeling</Title>
            {!loading && <Input onSubmit={handleSearch} />}
            <LoadingComponent loading={loading} />
            <PasteResultsText theme={theme} type="medium">Past searches</PasteResultsText>
            <PastResults results={results} onClick={handleResultClick} />
            {modalContent.search && <ResultModal result={modalContent} onClose={() => setModalContent({})} />}
        </ScreenContainer>
    );
}

export default PublicMoodScreen;