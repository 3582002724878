import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import { useTypinContext } from '../../../contexts/TypinContext';
import { useTheme } from '../../../contexts/ThemeContext';
import { FaEarthAmericas } from "react-icons/fa6";
import CustomText from "../../atoms/CustomText";
import { TIME_SETTINGS, LANGUAGES } from "../../../constants/typin";
import {formatLanguageString} from "../../../utils/typinUtils";

const MenuBarContainer = styled.nav`
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.colors.cardBackground};
    padding: 13px 17px;
    border-radius: 10px;
    
    @media (max-width: 800px) {
        top: 20px;
        left: 10px;
        transform: none;
    }
`;

const Section = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
`;

const Divider = styled.div`
    width: 2px;
    height: 25px;
    background-color: ${props => props.theme.colors.border};
    margin: 0 10px;
`;

const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    position: relative;
    padding-right: 10px;
`;

const LanguageList = styled.div`
    display: ${props => (props.expanded ? 'flex' : 'none')};
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    max-height: 200px;
    overflow-y: auto;
    background-color: ${props => props.theme.colors.cardBackground};
    padding: 13px 17px;
    border-radius: 10px;
    position: absolute;
    // width: calc(100% - 20px);
    top: 100%;
    left: -17px;
    z-index: 10;
    
//     style the scrollbar
    &::-webkit-scrollbar {
        width: 7px;
    }
    
    &::-webkit-scrollbar-track {
        background: ${props => props.theme.colors.cardBackground};
    }
    
    &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.colors.border};
        border-radius: 10px;
    }
`;

const TimeSettings = styled(Section)`
    gap: 8px;
`;

const TimeSetting = styled(CustomText)`
    transition: transform 0.2s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    color: ${props => props.selected ? props.theme.colors.primary : props.theme.colors.text};
    font-size: 1.1em;
    cursor: pointer;
    opacity: ${props => props.selected ? 1 : 0.6};
    
    &:hover {
        transform: scale(1.1);
        opacity: 0.7;
    }
    
    &:active {
        transform: scale(0.98);
    }
`;

const GlobeIcon = styled(FaEarthAmericas)`
    font-size: 1.1em;
`;

const LanguageText = styled(CustomText)`
    color: ${props => props.theme.colors.text};
    font-size: 1.1em;
    opacity: 0.6;
    white-space: nowrap;
    text-align: left;
    transition: transform 0.2s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    
    &:hover {
        opacity: 0.7;
        cursor: pointer;
        transform: scale(1.02);
    }
   
    &:active {
        transform: scale(0.98);
    }
`;

const LanguageSelect = styled(CustomText)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: ${props => props.theme.colors.text};
    font-size: 1.1em;
    cursor: pointer;
    opacity: 0.6;
    transition: transform 0.2s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
        opacity: 0.7;
    }
    
    &:active {
        transform: scale(0.98);
    }
`;


const MenuBar = () => {
    const { language, updateLanguage, timeSetting, updateTimeSetting } = useTypinContext();
    const [languageExpanded, setLanguageExpanded] = useState(false);
    const { theme } = useTheme();

    return (
        <MenuBarContainer theme={theme}>
            <LeftSection>
                <LanguageSelect
                    theme={theme}
                    type="medium"
                    onClick={() => setLanguageExpanded(!languageExpanded)}
                >
                    <GlobeIcon theme={theme} />
                    {formatLanguageString(language, window.innerWidth < 800)}
                </LanguageSelect>
                <LanguageList theme={theme} expanded={languageExpanded}>
                    {LANGUAGES.map(lang => (
                        <LanguageText
                            key={lang}
                            theme={theme}
                            type="medium"
                            onClick={() => {
                                updateLanguage(lang);
                                setLanguageExpanded(false);
                            }}
                        >
                            {lang === language && '•  '}
                            {formatLanguageString(lang, window.innerWidth < 800)}
                        </LanguageText>
                    ))}
                </LanguageList>
            </LeftSection>
            <Divider theme={theme} />
            <TimeSettings theme={theme}>
                {TIME_SETTINGS.map(time => (
                    <TimeSetting
                        key={time}
                        theme={theme}
                        selected={timeSetting === time}
                        onClick={() => updateTimeSetting(time)}
                        type="medium"
                    >
                        {time}
                    </TimeSetting>
                ))}
            </TimeSettings>
        </MenuBarContainer>
    );
}

export default MenuBar;