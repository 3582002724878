import React from 'react';
import { useTheme } from "../../../contexts/ThemeContext";
import styled from 'styled-components';
import CustomText from "../../atoms/CustomText";
import LoadingAnimation from "../../atoms/LoadingAnimation";
import {useSimlrContext} from "../../../contexts/SimlrContext";

const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const LoaderText = styled(CustomText)`
    font-size: 20px;
    margin-right: 10px;
    color: ${props => props.theme.simlrColors.text};
`;

const SimlrLoader = () => {
    const { theme } = useTheme();
    const { loading } = useSimlrContext();
    if (!loading) {
        return null;
    }

    return (
        <LoaderContainer>
            <LoaderText type="medium" theme={theme}>Loading</LoaderText>
            <LoadingAnimation />
        </LoaderContainer>
    );
}

export default SimlrLoader;