import React from "react";
import { useTheme } from "../../../contexts/ThemeContext";
import { getDayAndMonthString } from "../../../utils/dateUtil";
import styled from "styled-components";
import CustomText from "../../atoms/CustomText";
import { BsThreeDotsVertical } from "react-icons/bs";
import { PiShareFatBold } from "react-icons/pi";
import { useSimlrContext } from "../../../contexts/SimlrContext";

const TopBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 1em;
`;

const ButtonSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
`;


const Title = styled(CustomText)`
    font-size: 1.8em;
    color: ${props => props.theme.simlrColors.text};
`;

const ThreeBarsIcon = styled(BsThreeDotsVertical)`
    color: ${props => props.theme.simlrColors.text};
    font-size: 1.5em;
    border-radius: 50%;
    padding: 3px;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    
    &:hover {
        cursor: pointer;
        background-color: #00000020;
    }
    
    &:active {
        background-color: #00000040;
        transform: scale(0.95);
    }
`;

const ShareIcon = styled(PiShareFatBold)`
    color: ${props => props.theme.simlrColors.text};
    font-size: 1.5em;
    border-radius: 50%;
    padding: 4px;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    
    &:hover {
        cursor: pointer;
        background-color: #00000020;
    }
    
    &:active {
        background-color: #00000040;
        transform: scale(0.95);
    }
`;

const SimlrTopBar = ({ setShowSettings, showSettings }) => {
    const { theme } = useTheme();
    const { selectedDate } = useSimlrContext();

    const shareGame = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Simlr',
                text: 'Play a game of Simlr with me!',
                url: 'https://siii.fun/simlr'
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        }
    }

    return (
        <TopBar>
            <Title type="bold" theme={theme}>
                {getDayAndMonthString(selectedDate)}
            </Title>
            <ButtonSection>
                <ShareIcon onClick={shareGame} theme={theme} />
                <ThreeBarsIcon onClick={() => setShowSettings(!showSettings)} theme={theme} />
            </ButtonSection>
        </TopBar>
    )
}

export default SimlrTopBar;
