import React from 'react';
import styled, {css} from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import { PiMoonBold, PiMoonFill } from "react-icons/pi";

const ThemeButtonContainer = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
`;

const iconStyles = css`
    font-size: 1.7em;
    opacity: 0.7;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: ${props => props.theme.colors.text};
  
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
    
    &:active {
        transform: scale(0.9);
    }
`;

const MoonIcon = styled(PiMoonBold)`
    ${iconStyles}
`;

const MoonFillIcon = styled(PiMoonFill)`
    ${iconStyles}
`;

const ThemeButton = () => {
    const { theme, toggleTheme } = useTheme();

    return (
        <ThemeButtonContainer>
            {theme.name === "light" ? (
                <MoonIcon onClick={toggleTheme} theme={theme} />
            ) : (
                <MoonFillIcon onClick={toggleTheme} theme={theme} />
            )}
        </ThemeButtonContainer>
    );
}

export default ThemeButton;