import React from "react";
import styled from "styled-components";

const StyledInput = styled.input`
    padding: 0.5em;
    margin: 0.5em;
    background: #fff;
    border: 1px solid #555;
    border-radius: 8px;
    font-size: 1.5em;
    font-family: 'ConcertOne', sans-serif;
    font-weight: bold;
    letter-spacing: 0.02em;
    width: calc(100% - 1em);

    &:focus {
        outline: none;
    }
    
    @media (max-width: 800px) {
        font-size: 1.3em;
    }
`;

const CustomInput = React.forwardRef(({ value, onChange, onKeyPress, placeholder, onSubmit, style, numeric, ...props }, ref) => {
    return <StyledInput
        ref={ref}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={numeric ? 'number' : 'text'}
        style={style}
        onKeyDown={onKeyPress}
        onKeyPress={(e) => e.key === 'Enter' && onSubmit && onSubmit()}
        {...props}
    />;
});

export default CustomInput;