import React from "react";
import { useTheme } from "../../../contexts/ThemeContext";
import styled from "styled-components";
import CustomText from "../../atoms/CustomText";
import {useScrambledContext} from "../../../contexts/ScrambledContext";

const InfoContainer = styled.div`
    position: absolute;
    top: -40px;
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

const InfoSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
`;

const InfoText = styled(CustomText)`
    font-size: 1.2em;
    color: ${props => props.theme.palette1.text};
    opacity: 0.9;
`;

const InfoTextFaded = styled(CustomText)`
    font-size: 1.2em;
    color: ${props => props.theme.palette1.grey};
    opacity: 0.5;
`;

const ScrambledInfo = () => {
    const { theme } = useTheme();
    const { attemptsLeft, minimumSwaps } = useScrambledContext();

    return (
        <InfoContainer>
            <InfoSection>
                <InfoTextFaded theme={theme}>Attempts Left:</InfoTextFaded>
                <InfoText theme={theme} type="semiBold">{attemptsLeft}</InfoText>
            </InfoSection>
            <InfoSection>
                <InfoTextFaded theme={theme}>Swaps Needed:</InfoTextFaded>
                <InfoText theme={theme} type="semiBold">{minimumSwaps}</InfoText>
            </InfoSection>
        </InfoContainer>
    );
}

export default ScrambledInfo;



