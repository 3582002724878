import React, {createContext, useContext, useState, useEffect, useCallback} from 'react';
import { scrambleWord } from "../utils/scrambledUtils";
import { ALL_WORDS } from "../constants/words";

const GameContext = createContext();

export const useScrambledContext = () => useContext(GameContext);

export const ScrambledProvider = ({ children }) => {
    const allWords = ALL_WORDS.reduce((acc, word) => {
        const length = word.length;
        if (!acc[length]) {
            acc[length] = [];
        }
        acc[length].push(word);
        return acc;
    }, {});
    const [gameOver, setGameOver] = useState(false);
    const [currentWord, setCurrentWord] = useState('happy');
    const [scrambledWord, setScrambledWord] = useState(scrambleWord(currentWord));
    const [attemptsLeft, setAttemptsLeft] = useState(5);
    const [minimumSwaps, setMinimumSwaps] = useState(0);
    const [difficulty, setDifficulty] = useState('medium');
    const lengths = {
        easy: [3, 4],
        medium: [5, 6, 7],
        hard: [8, 9, 10, 11, 12],
    };

    const calculateMinimumSwaps = (current, target) => {
        if (current.length !== target.length) throw new Error("Words must be of equal length.");

        let swaps = 0;
        let curArray = current.split('');
        const targetPositions = {};

        for (let i = 0; i < target.length; i++) {
            const char = target[i];
            if (!targetPositions[char]) {
                targetPositions[char] = [];
            }
            targetPositions[char].push(i);
        }

        const findSwapIndex = (char, startIdx) => {
            for (let i = startIdx; i < target.length; i++) {
                if (curArray[i] === char) {
                    return i;
                }
            }
            return -1;
        };

        for (let i = 0; i < curArray.length; i++) {
            if (curArray[i] !== target[i]) {
                const swapWithChar = target[i];
                const swapWithIndex = findSwapIndex(swapWithChar, i + 1);

                if (swapWithIndex === -1) {
                    throw new Error("Invalid input: target characters do not match current characters.");
                }

                [curArray[i], curArray[swapWithIndex]] = [curArray[swapWithIndex], curArray[i]];
                swaps++;
            }
        }

        return swaps;
    };


    const revealLetter = () => {
        let correctLetters = currentWord.split('');
        let scrambledLetters = scrambledWord.split('');

        let incorrectIndices = scrambledLetters
            .map((letter, index) => (letter !== correctLetters[index] ? index : null))
            .filter((index) => index !== null);

        if (incorrectIndices.length === 0) {
            return;
        }

        const randomIncorrectIndex = incorrectIndices[Math.floor(Math.random() * incorrectIndices.length)];
        const letterToPlaceCorrectly = correctLetters[randomIncorrectIndex];

        let targetIndices = scrambledLetters
            .map((letter, index) => (letter === letterToPlaceCorrectly && correctLetters[index] !== letter ? index : null))
            .filter((index) => index !== null);

        if (targetIndices.length === 0) {
            targetIndices = incorrectIndices.filter((index) => index !== randomIncorrectIndex);
        }

        if (targetIndices.length === 0) return;

        const targetIndex = targetIndices[Math.floor(Math.random() * targetIndices.length)];

        [scrambledLetters[randomIncorrectIndex], scrambledLetters[targetIndex]] = [scrambledLetters[targetIndex], scrambledLetters[randomIncorrectIndex]];
        setScrambledWord(scrambledLetters.join(''));
        decreaseAttempts();
    };

    const revealWord = () => {
        setScrambledWord(currentWord);
    };

    const getRandomLength = () => {
        const lengthsArray = lengths[difficulty];
        return lengthsArray[Math.floor(Math.random() * lengthsArray.length)];
    }

    const calculateAttempts = (word, scrambledWord) => {
        const minAttempts = calculateMinimumSwaps(scrambledWord, word);
        return minAttempts + 3;
    }

    const getRandomWord = (length) => {
        const wordsOfLength = allWords[length];
        if (!wordsOfLength || wordsOfLength.length === 0) {
            throw new Error(`No words of length ${length} found.`);
        }
        const randomIndex = Math.floor(Math.random() * wordsOfLength.length);
        return wordsOfLength[randomIndex];
    }

    const startNewGame = () => {
        const word = getRandomWord(getRandomLength());
        setGameOver(false);
        setCurrentWord(word);
        const newScrambledWord = scrambleWord(word);
        setScrambledWord(newScrambledWord);
        setAttemptsLeft(calculateAttempts(word, newScrambledWord));
        setMinimumSwaps(calculateMinimumSwaps(newScrambledWord, word));
    };

    // Check if the user has won
    const checkWinCondition = useCallback(() => {
        return currentWord === scrambledWord;
    }, [currentWord, scrambledWord]);

    // Decrease attempt count
    const decreaseAttempts = () => setAttemptsLeft(prev => prev - 1);

    useEffect(() => {
        if (attemptsLeft <= 0) {
            setGameOver(true);
        }
        if (checkWinCondition()) {
            setGameOver(true);
        }
        setMinimumSwaps(calculateMinimumSwaps(scrambledWord, currentWord));
    }, [scrambledWord, attemptsLeft, checkWinCondition, currentWord]);

    return (
        <GameContext.Provider value={{ gameOver, setGameOver, currentWord, scrambledWord, setScrambledWord, attemptsLeft, setAttemptsLeft, minimumSwaps, startNewGame, decreaseAttempts, revealLetter, revealWord, difficulty, setDifficulty }}>
            {children}
        </GameContext.Provider>
    );
};
