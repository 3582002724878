import React from "react";
import styled from "styled-components";
import { useTheme } from '../../../contexts/ThemeContext';
import { useTypinContext } from '../../../contexts/TypinContext';
import Countdown from "../../molecules/Typin/Countdown";
import TypinArea from "../../molecules/Typin/TypinArea";
import MenuBar from "../../molecules/Typin/MenuBar";
import Results from "../../molecules/Typin/Results";
import Restart from "../../molecules/Typin/Restart";

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    margin-top: -50px;
`;

const WaitingState = () => {
    return (
        <InnerContainer>
            <MenuBar />
            <TypinArea />
        </InnerContainer>
    );
}

const PlayingState = () => {
    return (
        <InnerContainer>
            {/*<Countdown />*/}
            <TypinArea />
            {/*<Restart />*/}
        </InnerContainer>
    );
}

const ResultsState = () => {
    return (
        <InnerContainer>
            <MenuBar/>
            <Results/>
        </InnerContainer>
    );
}

const Game = () => {
    const { gameState } = useTypinContext();

    return (
        <InnerContainer>
            {gameState === "waiting" && <WaitingState />}
            {gameState === "playing" && <PlayingState />}
            {gameState === "ended" && <ResultsState />}
        </InnerContainer>
    );
}

export default Game;