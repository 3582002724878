import { createContext, useState, useContext, useEffect } from 'react';

const lightTheme = {
    name: 'light',
    colors: {
        primary: '#6b72fd',
        primaryDark: '#4f54e6',
        primaryFade: '#4f54e660',
        primaryFaded: '#4f54e630',
        lightPrimary: '#E3F2FD',
        secondary: '#1C1C1C',
        background: '#fff',
        cardBackground: '#e9e9e9',
        border: '#81818190',
        text: '#1A1A1A',
        untyped: '#1A1A1A60',
        stroke: '#C4C4C4',
        error: '#FF1744',
        success: '#00E676',
        warning: '#FF9100',
        info: '#2196F3',
        infoFade: 'rgba(33, 150, 243, 0.3)',
    },
    simlrColors: {
        primary: '#d4a373',
        background: '#fefae0',
        cardBackground: '#e9edc9',
        border: '#c9d6df',
        text: '#3A3A3A',
        stroke: '#C4C4C4',
        error: '#FF1744',
        success: '#00E676',
        warning: '#FF9100',
        info: '#2196F3',
        infoFade: 'rgba(33, 150, 243, 0.3)',
    },
    headlinesColors: {
        primary: '#ef233c',
        background: '#edf2f4',
        cardBackground: '#8d99ae',
        border: '#C4C4C4',
        text: '#2b2d42',
        stroke: '#C4C4C4',
        secondary: '#d90429',
        success: '#00E676',
        warning: '#FF9100',
        info: '#2196F3',
        infoFade: 'rgba(33, 150, 243, 0.3)',
    },
    palette1: {
        white: '#FFFFFF',
        background: '#f2e8cf',
        text: '#bc4749',
        brightPrimary: '#a7c957',
        primary: '#6a994e',
        secondary: '#386641',
        grey: '#3A3A3A',
    },
    typin: {
        correct: '#299247',
        incorrect: '#d92c38',
        untyped: '#1A1A1A60',
    }
};

const darkTheme = {
    name: 'dark',
    colors: {
        primary: '#6b72fd',
        primaryDark: '#4f54e6',
        primaryFade: '#4f54e660',
        primaryFaded: '#4f54e630',
        lightPrimary: '#E3F2FD',
        secondary: '#FFFFFF',
        background: '#1C1C1C',
        cardBackground: '#2C2C2C',
        colorBackground: '#2C2C2C',
        colorSecondaryBackground: '#3A3A3A',
        border: '#3A3A3A',
        text: '#FFFFFF',
        untyped: '#FFFFFF60',
        stroke: '#FFFFFF',
        error: '#FF1744',
        success: '#00E676',
        warning: '#FF9100',
        info: '#2196F3',
        infoFade: 'rgba(33, 150, 243, 0.3)',
    },
    simlrColors: {
        primary: '#d4a373',
        background: '#fefae0',
        cardBackground: '#e9edc9',
        border: '#c9d6df',
        text: '#3A3A3A',
        stroke: '#C4C4C4',
        error: '#FF1744',
        success: '#00E676',
        warning: '#FF9100',
        info: '#2196F3',
        infoFade: 'rgba(33, 150, 243, 0.3)',
    },
    headlinesColors: {
        primary: '#ef233c',
        background: '#2b2d42',
        cardBackground: '#8d99ae',
        border: '#C4C4C4',
        text: '#edf2f4',
        stroke: '#C4C4C4',
        secondary: '#d90429',
        success: '#00E676',
        warning: '#FF9100',
        info: '#2196F3',
        infoFade: 'rgba(33, 150, 243, 0.3)',
    },
    palette1: {
        white: '#FFFFFF',
        background: '#f2e8cf',
        text: '#bc4749',
        brightPrimary: '#a7c957',
        primary: '#6a994e',
        secondary: '#386641',
        grey: '#3A3A3A',
    },
    typin: {
        correct: '#299247',
        incorrect: '#d92c38',
        untyped: '#FFFFFF60',
    }
};

const ThemeContext = createContext(lightTheme);

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(darkTheme);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        const handleMediaChange = (e) => {
            setTheme(e.matches ? darkTheme : lightTheme);
        }

        mediaQuery.addListener(handleMediaChange);
        handleMediaChange(mediaQuery);

        return () => mediaQuery.removeListener(handleMediaChange);
    }, []);

    const toggleTheme = () => {
        setTheme(theme.name === 'light' ? darkTheme : lightTheme);
    }

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}