import React from "react";
import { useTheme } from "../../../contexts/ThemeContext";
import styled from "styled-components";
import CustomText from "../../atoms/CustomText";
import { useSimlrContext } from "../../../contexts/SimlrContext";

const InfoBarContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
`;

const InfoText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
`;

const InfoTextLabel = styled(CustomText)`
    font-size: 1.1em;
    opacity: 0.5;
    color: ${props => props.theme.simlrColors.text};
`;

const InfoTextValue = styled(CustomText)`
    font-size: 1.2em;
    color: ${props => props.theme.simlrColors.text};
`;

const SimlrInfoBar = () => {
    const { theme } = useTheme();
    const { gameNumber, numGuesses } = useSimlrContext();

    return (
        <InfoBarContainer>
            <InfoText>
                <InfoTextLabel type="medium" theme={theme}>Game:</InfoTextLabel>
                <InfoTextValue type="semiBold" theme={theme}>#{gameNumber}</InfoTextValue>
            </InfoText>
            <InfoText>
                <InfoTextLabel type="medium" theme={theme}>Guesses:</InfoTextLabel>
                <InfoTextValue type="semiBold" theme={theme}>{numGuesses}</InfoTextValue>
            </InfoText>
        </InfoBarContainer>
    );
}

export default SimlrInfoBar;

