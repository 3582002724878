import React from 'react';
import styled from "styled-components";
import '../../assets/fonts/fonts.css';
import { useTheme } from "../../contexts/ThemeContext";

const StyledText = styled.p`
    font-size: 1.5em;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.02em;
    text-align: center;
    margin: 0;
    color: ${props => props.theme.colors.text};
`;

const CustomText = ({ type = 'regular', style, children, ...props }) => {
    const { theme } = useTheme();
    const fontStyles = {
        regular: { fontFamily: 'Poppins', fontWeight: 400 },
        bold: { fontFamily: 'PoppinsBold', fontWeight: 700 },
        italic: { fontFamily: 'Poppins', fontStyle: 'italic' },
        light: { fontFamily: 'Poppins', fontWeight: 300 },
        medium: { fontFamily: 'PoppinsMedium', fontWeight: 500 },
        semiBold: { fontFamily: 'PoppinsSemiBold', fontWeight: 600 },
        black: { fontFamily: 'Poppins', fontWeight: 900 },
        gloria: { fontFamily: 'GloriaHallelujah', fontWeight: 400 },
    };

    // if style has its own fontFamily, it will override the type
    const combinedStyle = { ...fontStyles[type], ...style };

    return <StyledText style={combinedStyle} theme={theme} {...props}>{children}</StyledText>;
}

export default CustomText;